import React, { Component } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { IconSquarePlus, IconDownload, IconX } from '@tabler/icons'
import * as Types from './actions'


class ModalAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
            name: "",
            code: this.uniqueId()
        })
    }

    uniqueId = () => {
        const dateString = Date.now().toString(36); // it is unique to your machine and now.
        const randomness = Math.random().toString(36).substr(2); // add some random factor to the value.
        return dateString + randomness;
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const name = event.target.getElementsByTagName('input')['name'].value;
        const code = event.target.getElementsByTagName('input')['code'].value;
        const category = { name, code };
        this.props.dispatch({ type: Types.CATEGORY_CREATE.REQUESTED, detail: category })
        this.toggle();
    }

    render() {
        const { name, code } = this.state
        return (
            <React.Fragment>
                <Button className="btn btn-primary" onClick={this.toggle}><IconSquarePlus/>Add</Button>
                <Modal show={this.state.isOpen} onHide={this.toggle} className="modal-blur" centered size="lg" >
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>New category</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-3 row">
                                <div className="col-lg-6">
                                    <label htmlFor="name" className="form-label required">Name</label>
                                    <input id="name" type="text" className="form-control" name="name" placeholder="Name category" defaultValue={name} />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="code" className="form-label required">Code</label>
                                    <input id="code" type="text" className="form-control" name="code" placeholder="Code category" value={code} readOnly />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link-secondary" onClick={this.toggle}><IconX/>Cancel</Button>
                            <Button variant="primary ms-auto" type="submit"><IconDownload />Save</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalAdd;

