import { combineReducers } from 'redux';
import languageProviderReducer from './containers/LanguageProvider/reducer';
import authReducer from './containers/Login/reducers';
import meReducer from './reducers/me';
import profileReducer from './containers/Profile/reducer';
import chatReducer from './containers/ChatPoolp/reducer';
import attributeReducer from './containers/Attributes/reducer';
import templateReducer from './containers/Templates/reducer';
import importCsvReducer from './containers/Generators/reducer';
import exportCsvReducer from './containers/Generators/reducerExport';
import categoryReducer from './containers/Categories/reducer';


const allReducers = combineReducers({
    language: languageProviderReducer,
    auth: authReducer,
    me: meReducer,
    profile: profileReducer,
    attribute: attributeReducer,
    template: templateReducer,
    importCsv: importCsvReducer,
    exportCsv: exportCsvReducer,
    category: categoryReducer,
    chat: chatReducer
})

export default allReducers;