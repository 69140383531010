/* eslint-disable no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';

import * as services from './services';
import * as ActionTypes from './actions';

export function* getListChat(action) {
    try {
        var result = yield call(services.getListChat, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.CHAT_LIST.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.CHAT_LIST.SUCCEEDED, result });
        }
    } catch (e) {
        yield put({ type: ActionTypes.CHAT_LIST.FAILED, error: "This action is not valid!" });
    }
}

export function* getChat(action) {
    try {
        var result = yield call(services.getChat, action.detail)
        if (result.error) {
            yield put({ type: ActionTypes.CHAT_DETAIL.FAILED, error: result.errMessage })
        } else {
            yield put({ type: ActionTypes.CHAT_DETAIL.SUCCEEDED, result })
        }
    } catch (e) {
        yield put({ type: ActionTypes.CHAT_DETAIL.FAILED, error: "This action is not valid!" })
    }
}

export default function* attributeSaga() {
    yield takeLatest(ActionTypes.CHAT_LIST.REQUESTED, getListChat);
    yield takeLatest(ActionTypes.CHAT_DETAIL.REQUESTED, getChat);
}