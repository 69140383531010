import React from "react";
import { Route } from 'react-router-dom';

const OutPageLayout = ({ render, ...rest }) => {
    return (
        <Route
            {...rest}
            render={matchProps => (
                <div className="page page-center">
                    {render(matchProps)}
                </div>
            )}
        />
    );
};

export default OutPageLayout;