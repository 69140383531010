import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import allReducers from './reducers';
import loginSaga from './containers/Login/saga';
import atlasSaga from './sagas/atlasSaga';
import profileSaga from './containers/Profile/saga';
import chatSaga from './containers/ChatPoolp/saga';
import attributeSaga from './containers/Attributes/saga';
import templateSaga from './containers/Templates/saga';
import importCsvSaga from './containers/Generators/saga';
import categorySaga from './containers/Categories/saga';


const sagaMiddleware = createSagaMiddleware();
const store = createStore(allReducers, composeWithDevTools( applyMiddleware(sagaMiddleware) ));

sagaMiddleware.run(loginSaga);
sagaMiddleware.run(atlasSaga);
sagaMiddleware.run(profileSaga);
sagaMiddleware.run(chatSaga);
sagaMiddleware.run(attributeSaga);
sagaMiddleware.run(templateSaga);
sagaMiddleware.run(importCsvSaga);
sagaMiddleware.run(categorySaga);

export default store;
