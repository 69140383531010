// Role use
export const ROLE = {
    ADMIN: "ADMIN",
    USER: "USER",
};

// Container name
export const CONTAINER_NAME = {
}

// Regex
export const regexSystem = {
    email: {
        pattern: {
            match: /^\w+([.-]?\w+)*@\w+([-]?\w+)*(\.\w)+([.-]?\w+)+$/,
            message: 'Email is invalid',
        }
    },
    ip: {
        pattern: {
            match: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            message: 'Ip is invalid'
        }
    },
    phone: {
        pattern: {
            match: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/,
            message: 'Phone number is invalid'
        }
    }
}


export const PASSWORD_LENGTH = {
    MIN: 8,
    MAX: 32
}