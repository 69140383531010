import React, { Suspense } from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './assets/index.scss';
import 'react-chat-elements/dist/main.css'
import Spinner from './components/Spinner';
import { UnAuthorizedRoute, AuthorizedRoute, MainRouter } from './containers/Routers';
class App extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<Suspense fallback={Spinner}>
					<Switch>
						<Route path='/auth/login' component={UnAuthorizedRoute} />
						<AuthorizedRoute component={MainRouter} />
					</Switch>
				</Suspense>
                <NotificationContainer />
			</BrowserRouter>
		)
	}
}

export default App;
