import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as Storage from '../../storage';

/**
 * Security account Depend on JWT_TOKEN
 */
class AuthorizedRoute extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthorize: localStorage.getItem(Storage.JWT_TOKEN) ? true: false,
            currentPage: window.location.pathname + props.location.search || null
        };
    }

    /**
     * Control render 
     * Check isAuthorize
     * Not auth: auto Redirect to /auth/login
     */
    render() {
        const { component: CP, ...rest } = this.props;
        return (
            <Route {...rest} render={props => {
                return this.state.isAuthorize
                    ? <CP {...props} />
                    : <Redirect to={`/auth/login?redirect=${this.state.currentPage}`} />
            }} />
        )
    }
}

export default AuthorizedRoute;