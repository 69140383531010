import React from 'react';

const Footer = (props) => (

    <footer className="footer footer-transparent d-print-none">
        <div className="container">
            <div className="row text-center align-items-center flex-row-reverse">
                <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                    <code>
                    <ul className="list-inline list-inline-dots mb-0">
                        <li className="list-inline-item">Copyright&copy;2021 <a href="https://www.neotiq.com" className="link"> https://www.neotiq.com</a>. All rights reserved.</li>
                        <li className="list-inline-item">
                            <a href="/" className="link-secondary" rel="noopener">v1.0.0-beta3</a>
                        </li>
                    </ul>
                    </code>
                </div>
            </div>
        </div>
    </footer>
);

export default React.memo(Footer);