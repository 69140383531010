import { call, put, takeLatest } from 'redux-saga/effects';

import * as services from './services';
import * as ActionTypes from './actions';

export function* getListCsv(action) {
    try {
        var result = yield call(services.getListCsv, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.CSV_LIST.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.CSV_LIST.SUCCEEDED, result });
        }
    } catch (e) {
        yield put({ type: ActionTypes.CSV_LIST.FAILED, error: "This action is not valid!" });
    }
}

export function* postCsv(action) {
    try {
        var result = yield call(services.postCsv, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.CSV_CREATE.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.CSV_CREATE.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.CSV_CREATE.FAILED, error: "This action is not valid" });
    }
}

export function* deleteCsv(action) {
    try {
        var result = yield call(services.deleteCsv, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.CSV_REMOVE.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.CSV_REMOVE.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.CSV_REMOVE.FAILED, error: "This action is not valid!" });
    }
}

export function* exportCsv(action) {
    try {
        var result = yield call(services.exportCsv, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.CSV_EXPORT.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.CSV_EXPORT.SUCCEEDED, result });
        }
    } catch (e) {
        yield put({ type: ActionTypes.CSV_EXPORT.FAILED, error: "This action is not valid!" });
    }
}


export default function* importCsvSaga() {
    yield takeLatest(ActionTypes.CSV_LIST.REQUESTED, getListCsv);
    yield takeLatest(ActionTypes.CSV_CREATE.REQUESTED, postCsv);
    yield takeLatest(ActionTypes.CSV_REMOVE.REQUESTED, deleteCsv);
    yield takeLatest(ActionTypes.CSV_EXPORT.REQUESTED, exportCsv);

}