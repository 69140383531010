/* eslint-disable react/prop-types */
import React, { useState, useLayoutEffect } from 'react';
import _ from 'lodash';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { IconSquarePlus, IconCircleMinus } from '@tabler/icons';
import AddAttribute from './AddAttribute';

const AddProduct = (props) => {
    const [products, setProducts] = useState(props.products);
    const [disabled, setDisabled] = useState(false);
    useLayoutEffect(() => {
        if (_.size(props.products) > 1) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    });
    const addProduct = () => {
        const dataUpdate = [...products, {
            sku: '',
            attributes: [{
                label: '',
                value: '',
                err_label: false,
                err_value: false
            }],
            err_sku: false
        }];
        setProducts(dataUpdate)
        props.handleProduct(dataUpdate);
    }

    const updateProducts = (prodId, attributes) => {
        const dataTmp = [...products];
        if (dataTmp[prodId]) {
            dataTmp[prodId].attributes = attributes;
            setProducts(dataTmp);
            props.handleProduct(dataTmp);
        }
    }

    const deleteProduct = (e, idx) => {
        if (_.size(products) > 1) {
            const dataTmp = products.filter(function (item, key) {
                return key !== idx;
            })
            setProducts(dataTmp);
            props.handleProduct(dataTmp);
        }
    }

    const handleChange = (event) => {
        let data_name = event.target.name.split('-');
        if (data_name[1]) {
            let prodId = data_name[1];
            if (['sku'].includes(data_name[0])) {
                const dataTmp = [...products];
                if (event.target.value.trim() === '') {
                    dataTmp[prodId]['err_' + data_name[0]] = true;
                } else {
                    dataTmp[prodId]['err_' + data_name[0]] = false;
                }
                dataTmp[prodId][data_name[0]] = event.target.value;
                setProducts(dataTmp);
                props.handleProduct(dataTmp);
            }
        }
    }
    return (<div className='card mt-2'>
        <div className='card-header'>
            <h3>Products <span><IconSquarePlus className='link-primary cl-pointer' size={24} onClick={addProduct} /></span></h3>
        </div>
        <div className='card-body'>
            {Array.isArray(products) && _.size(products) > 0 && products.map((val, idx) => {
                let skuId = `sku-${idx}`;
                return <div key={idx}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id={skuId}>Sku<span className='required ms-1'>(*)</span></InputGroup.Text>
                        <Form.Control id={skuId} name={skuId} aria-describedby={skuId} className={`form-control round ${products[idx].err_sku && 'is-invalid'}`} value={products[idx].sku} onChange={(e) => handleChange(e)} />
                        <InputGroup.Text className='cl-pointer' disabled={disabled} onClick={(e) => deleteProduct(e, idx)}><IconCircleMinus /></InputGroup.Text>
                    </InputGroup>
                    <div className='card mt-2'>
                        <AddAttribute product={val} updateProducts={updateProducts} prodId={idx} />
                    </div>
                    <hr/>
                </div>
            })}
        </div>
    </div>);
}

export default AddProduct;