import fetch from 'isomorphic-fetch';
import * as Utils from '../../utils';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;

export const postLogin = (data) => {
    return fetch(`${BASE_URL}/auth/login`, {
        method: 'POST',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    }).then(Utils.handleAuthNon200s)
}