/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toast from 'react-bootstrap/Toast';
import { IconEye, IconEyeOff, IconAlertTriangle, IconLogin } from '@tabler/icons';
import _ from 'lodash';

import * as ActionTypes from './actions';
import * as Storage from '../../storage';
import Logo from '../../components/Logo';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isShow: false,
            isLoading: false,
            isError: false
        };
    }
    handleLogin = (event) => {
        event.preventDefault();

        this.setState({ isLoading: true });
        const email = _.trim(event.target.getElementsByTagName('input')['email'].value);
        const password = _.trim(event.target.getElementsByTagName('input')['password'].value);
        const userAuth = { email, password };
        if (_.isEmpty(email) || _.isUndefined(email) || _.isEmpty(password) || _.isUndefined(password)) {
            return this.setState({
                isLoading: false,
                isError: true,
                messageWarning: 'Email and password cannot be left blank'
            });
        }
        this.props.dispatch({ type: ActionTypes.POST_AUTH_REQUESTED, detail: userAuth });
    }

    toggleShowError = () => {
        this.setState({
            isError: false,
            messageWarning: ""
        });
    }

    showItem = () => {
        let domShow = <span className="input-group-text cl-pointer" onClick={()=> {this.setState({isShow: !this.state.isShow})}}>
            <span className="link-secondary" title="Show password" data-bs-toggle="tooltip"><IconEyeOff /></span>
        </span>;
        if (!this.state.isShow) {
            domShow = <span className="input-group-text cl-pointer" onClick={()=> {this.setState({isShow: !this.state.isShow})}}>
                <span className="link-secondary" title="Hidden password" data-bs-toggle="tooltip"><IconEye /></span>
            </span>;
        }
        return domShow;
    }

    static getDerivedStateFromProps(props, state) {
        const { resultAuth } = props;
        const authData = _.get(resultAuth, 'detail.result', undefined);
        let inProgress = _.get(resultAuth, 'inProgress', undefined);
        const done = _.get(resultAuth, 'done', undefined);
        const error = _.get(resultAuth, 'error', undefined);
        inProgress ? state.isLoading = true : state.isLoading = false;
        if (authData && done) {
            localStorage.setItem(Storage.JWT_TOKEN, JSON.stringify(resultAuth.detail.result.token));
            localStorage.setItem(Storage.USER_INFO, JSON.stringify(resultAuth.detail.result.userInfo));
            let location = {
                pathname: '/',
                state: { fromLogin: true }
            };
            if (state.redirectPage) {
                location = {
                    pathname: state.redirectPage,
                    state: { fromLogin: true }
                };
            }
            props.history.push(location);
        }

        if (error && done) {
            state.isError = true
            state.messageWarning = resultAuth.message
        }
        return state;
    }


    render() {
        const { isLoading, messageWarning, isError } = this.state;
        
        const classLoading = isLoading ? " btn-loading" : "";
        return (
            <div className="container-tight py-4">
                <div className="text-center mb-4">
                    <a href="/"><Logo /></a>
                </div>
                <form onSubmit={this.handleLogin} className="card card-md">
                    <div className="card-body">
                        <h2 className="card-title text-center mb-4">Login to your account</h2>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email address</label>
                            <input id="email" type="email" className="form-control" placeholder="Enter email" defaultValue="" autoComplete="username" />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="password" className="form-label">Password</label>
                            <div className="input-group input-group-flat">
                                <input id="password" type={this.state.isShow ? "text" : "password"} className="form-control" placeholder="Password" name="password" defaultValue="" autoComplete="current-password" />
                                {this.showItem()}
                            </div>
                        </div>
                        
                        <Toast show={isError} className="alert alert-warning alert-dismissible" role="alert">
                            <div className="d-flex">
                                <div><IconAlertTriangle className="alert-icon" /></div>
                                <div>
                                    <h4 className="alert-title">{'Something went wrong'}</h4>
                                    <div className="text-muted">{messageWarning}</div>
                                </div>
                            </div>
                            <a className="btn-close" onClick={this.toggleShowError} />
                        </Toast>
                        
                        <div className="mb-2">
                            <label className="form-check">
                                <input type="checkbox" className="form-check-input" />
                                <span className="form-check-label">Remember me on this device</span>
                            </label>
                        </div>
                        <div className="form-footer">
                            <button type="submit" className={"btn btn-primary w-100 " + classLoading}>Sign in</button>
                        </div>
                    </div>
                    <div className="hr-text">or</div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col"><a href="#" className="btn btn-white w-100">
                                <svg className="icon text-github" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5" /></svg>
                                Login with Github
                            </a>
                            </div>
                            <div className="col"><a href="#" className="btn btn-white w-100">
                                <svg className="icon text-twitter" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z" /></svg>
                                Login with Twitter
                            </a>
                            </div>
                        </div>
                    </div>
                </form>
                {/* <div className="text-center text-muted mt-3">
                    Don't have account yet? <a href="./sign-up.html" tabIndex="-1">Sign up</a>
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    resultAuth: state.auth
})

export default connect(mapStateToProps)(Login);
