/* eslint-disable no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';

import * as services from './services';
import * as ActionTypes from './actions';

export function* getProfile() {
    try {
        var result = yield call(services.getProfile);
        if (result.error) {
            yield put({ type: ActionTypes.PROFILE_DETAIL.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.PROFILE_DETAIL.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.PROFILE_DETAIL.FAILED, error: 'This action is not valid!' });
    }
};

export function* putProfile(action) {
    try {
        var result = yield call(services.putProfile, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.PROFILE_UPDATE.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.PROFILE_UPDATE.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.PROFILE_UPDATE.FAILED, error: 'This action is not valid' });
    }
};

export default function* ProfileSaga() {
    yield takeLatest(ActionTypes.PROFILE_DETAIL.REQUESTED, getProfile);
    yield takeLatest(ActionTypes.PROFILE_UPDATE.REQUESTED, putProfile);
}