import fetch from 'isomorphic-fetch';
import * as Utils from '../../utils';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;

export const getListTemplate = async (params) => {
    const response = await fetch(`${BASE_URL}/templates?${Utils.ObjectToQueryString(params)}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const getTemplate = async (params) => {
    const response = await fetch(`${BASE_URL}/templates/${params.id}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const postTemplate = async (data) => {
    const response = await fetch(`${BASE_URL}/templates`, {
        method: 'POST',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    });
    return Utils.handleNon200s(response);
}

export const putTemplate = async (data) => {
    const response = await fetch(`${BASE_URL}/templates/` + data.id, {
        method: 'PUT',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    });
    return Utils.handleNon200s(response);
}

export const deleteTemplate = async (params) => {
    const response = await fetch(`${BASE_URL}/templates/` + params.id, {
        method: 'DELETE',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(params)
    });
    return Utils.handleNon200s(response);
}

export const importTemplate = async (data) => {
    const response = await fetch(`${BASE_URL}/templates/importer`, {
        method: 'POST',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    });
    return Utils.handleNon200s(response);
}

export const countCategory = async (params) => {
    const response = await fetch(`${BASE_URL}/templates/count-category/` + params.id, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}