export const TEMPLATE_LIST = {
    REQUESTED: "TEMPLATE_LIST_REQUESTED",
    SUCCEEDED: "TEMPLATE_LIST_SUCCEEDED",
    FAILED: "TEMPLATE_LIST_FAILED",
    RESET: "TEMPLATE_LIST_RESET",
}

export const TEMPLATE_CREATE = {
    REQUESTED: "TEMPLATE_CREATE_REQUESTED",
    SUCCEEDED: "TEMPLATE_CREATE_SUCCEEDED",
    FAILED: "TEMPLATE_CREATE_FAILED",
    RESET: "TEMPLATE_CREATE_RESET",
}

export const TEMPLATE_UPDATE = {
    REQUESTED: "TEMPLATE_UPDATE_REQUESTED",
    SUCCEEDED: "TEMPLATE_UPDATE_SUCCEEDED",
    FAILED: "TEMPLATE_UPDATE_FAILED",
    RESET: "TEMPLATE_UPDATE_RESET",
}

export const TEMPLATE_REMOVE = {
    REQUESTED: "TEMPLATE_REMOVE_REQUESTED",
    SUCCEEDED: "TEMPLATE_REMOVE_SUCCEEDED",
    FAILED:  "TEMPLATE_REMOVE_FAILED",
    RESET: "TEMPLATE_REMOVE_RESET",
}

export const TEMPLATE_DETAIL = {
    REQUESTED: "TEMPLATE_DETAIL_REQUESTED",
    SUCCEEDED: "TEMPLATE_DETAIL_SUCCEEDED",
    FAILED: "TEMPLATE_DETAIL_FAILED",
    RESET: "TEMPLATE_DETAIL_RESET",
}

export const TEMPLATE_IMPORT = {
    REQUESTED: "TEMPLATE_IMPORT_REQUESTED",
    SUCCEEDED: "TEMPLATE_IMPORT_SUCCEEDED",
    FAILED: "TEMPLATE_IMPORT_FAILED",
    RESET: "TEMPLATE_IMPORT_RESET",
}

export const TEMPLATE_COUNT = {
    REQUESTED: "TEMPLATE_COUNT_REQUESTED",
    SUCCEEDED: "TEMPLATE_COUNT_SUCCEEDED",
    FAILED: "TEMPLATE_COUNT_FAILED",
    RESET: "TEMPLATE_COUNT_RESET",
}