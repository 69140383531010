import fetch from 'isomorphic-fetch';
import * as Utils from '../../utils';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;

export const getProducts = async (params) => {
    const response = await fetch(`${BASE_URL}/ai-generations?${Utils.ObjectToQueryString(params)}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const exportProducts = async (params) => {
    const response = await fetch(`${BASE_URL}/ai-exports?${Utils.ObjectToQueryString(params)}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const getProduct = async (params) => {
    const response = await fetch(`${BASE_URL}/ai-generations/${params.id}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const postProduct = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/ai-generations`, {
            method: 'POST',
            headers: Utils.commonHeaders(),
            body: JSON.stringify(data)
        });
        return Utils.handleNon200s(response);
    } catch (error) {
        console.log('error: ', error);
        return false;
    }
};

export const deleteProduct = async (params) => {
    const response = await fetch(`${BASE_URL}/ai-generations/${params.id}`, {
        method: 'DELETE',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}