import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { IconChevronDown, IconChevronUp, IconSearch, IconX, IconNotes } from '@tabler/icons'
import { Table, Spinner, Form } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications'

import { formatDateString, LIMIT, TIME_OUT, INIT_PAGE, PAGE_DISPLAY, SORT_ASC, SORT_DESC } from '../../utils'
import * as ActionTypes from '../../actions/types'
import * as Types from './actions'
import Aside from '../../components/Aside'
import Header from '../../components/Header'
import Paging from '../../components/Paging'
import Footer from '../../components/Footer'

import NoData from '../../components/NoData'
import ConfirmRemove from './components/ConfirmRemove'
import ModalImport from './modalImport'
import ModalAdd from './modalAdd'
import ModalEdit from './modalEdit'
import PopoverCategory from './popoverCategory'

/**
 * Templates page
 * constructor: data userinfo
 */
class Templates extends React.Component {
    constructor(props) {
        super(props)
        const userInfo = _.get(props, "resultMe.detail.result.userInfo", _.get(props, "resultAuth.detail.result.userInfo", null))
        const QUERY_STRING = queryString.parse(props.location.search) || null
        this.state = {
            userInfo: userInfo,
            error: false,
            errorMessage: null,
            isLoading: false,
            filters: {},
            location: props.location,
            searchText: "",
            sortBy: "createdAt",
            order: SORT_DESC,
            getRefreshTemplates: this.getRefreshTemplates.bind(this)
        }
        this.requireMe(userInfo, this.state)
        this.getInitList(_.isNaN(_.parseInt(_.get(QUERY_STRING, 'page', INIT_PAGE))) ? INIT_PAGE : _.parseInt(_.get(QUERY_STRING, 'page', INIT_PAGE)), this.state)
    }

    /**
     * Init data display information
     */
    requireMe(userInfo, state) {
        if (_.isNull(userInfo)) {
            this.props.dispatch({ type: ActionTypes.GET_ME_REQUESTED })
            state.isLoading = true
        }
    }

    getInitList = (pageActive, state) => {
        const QUERY_LIST = {
            sortBy: state.sortBy,
            order: state.order,
            limit: LIMIT,
            offset: (pageActive - 1) * LIMIT
        }
        state.pageActive = pageActive
        state.queyList = QUERY_LIST
        this.getActionList(QUERY_LIST)
    }

    getList = (pageActive, sortBy = null, order = null) => {
        const addOrder = order ? (this.state.order === SORT_DESC ? SORT_ASC : SORT_DESC) : this.state.order
        const addSortBy = sortBy ? sortBy : this.state.sortBy
        const QUERY_LIST = {
            sortBy: addSortBy,
            order: addOrder,
            limit: LIMIT,
            offset: (pageActive - 1) * LIMIT
        }
        if (this.state.searchText) {
            QUERY_LIST["name"] = this.state.searchText
            QUERY_LIST["type"] = this.state.searchText
            QUERY_LIST["comment"] = this.state.searchText
            QUERY_LIST["content"] = this.state.searchText
        }

        this.setState({
            order: addOrder,
            sortBy: addSortBy,
            queyList: QUERY_LIST,
            pageActive: pageActive
        })
        this.getActionList(QUERY_LIST)
    }

    getActionList = (queyList) => {
        this.props.dispatch({ type: Types.TEMPLATE_LIST.REQUESTED, detail: queyList })
    }

    /**
     * Receive props data
     * @param {*} props
     * @param {*} state 
     */
    static getDerivedStateFromProps(props, state) {
        const { resultAuth, resultMe, resultTemplate } = props
        const userInfo = resultMe.detail ? _.get(resultMe, "detail.result.userInfo", null) : _.get(resultAuth, "detail.result.userInfo", null)
        if (!_.isNull(userInfo)) {
            state.isLoading = false
            state.userInfo = userInfo
        }
        const { ListTemplate, CreateTemplate, UpdateTemplate, RemoveTemplate, ImportTemplate, CountCategory } = resultTemplate

        // Get list record
        var listTemp = _.get(ListTemplate, 'detail.result', null)
        if (ListTemplate.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = ListTemplate.message
        }
        if (_.has(listTemp, 'data')) {
            state.listTemp = listTemp.data
            state.pagination = listTemp.pagination
        }

        // Create a new record
        const newTemp = _.get(CreateTemplate, 'detail', null)
        if (CreateTemplate.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = CreateTemplate.message
        }
        if (_.has(newTemp, 'data')) {
            state.isLoading = false
            state.successCreate = true
            state.getRefreshTemplates(listTemp, state, props)
        }

        // Put the record
        const editTemp = _.get(UpdateTemplate, 'detail', null)
        if (UpdateTemplate.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = UpdateTemplate.message
        }
        if (_.has(editTemp, 'data')) {
            state.isLoading = false
            state.successUpdate = true
            state.getRefreshTemplates(listTemp, state, props)
        }

        // Remove the record
        const removeTemp = _.get(RemoveTemplate, 'detail', null)
        if (RemoveTemplate.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = RemoveTemplate.message
        }
        if (_.has(removeTemp, 'data')) {
            state.isLoading = false
            state.successRemove = true
            state.getRefreshTemplates(listTemp, state, props)
        }

        // Import the file csv
        const importTemp = _.get(ImportTemplate, 'detail', null)
        if (ImportTemplate.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = ImportTemplate.message
        }
        if (_.has(importTemp, 'data')) {
            state.isLoading = false
            state.successImport = true
            state.getRefreshTemplates(listTemp, state, props)
        }

        // OverlayTrigger show category relation template
        // const countCategory = _.get(CountCategory, 'detail', null)
        // if (CountCategory.error) {
        //     state.isLoading = false
        //     state.error = true
        //     state.errorMessage = CountCategory.message
        // }
        // if (_.has(countCategory, 'data')) {
        //     state.isLoading = false
        //     state.successCount = true
        //     state.countCat = countCategory.data
        // }

        return state
    }

    /**
     * Refresh data in getDerivedStateFromProps
     * @param {*} listTemp 
     * @param {*} state 
     * @param {*} props 
     * @returns 
     */
    getRefreshTemplates = (listTemp, state, props) => {
        let listData = [...listTemp.data]
        let template_query = state.queyList
        if (_.has(state, "searchText")) {
            template_query.name = state.searchText
            template_query.type = state.searchText
            template_query.comment = state.searchText
            template_query.content = state.searchText
        }
        if (listData.length <= 1) {
            const page = _.parseInt(_.get(state, "pageActive", null))
            if (page > 1) {
                const pageActive = page - 1
                props.history.push({
                    pathname: state.location.pathname,
                    search: "?page=" + (page - 1)
                })
                const QUERY_LIST = {
                    sortBy: state.sortBy,
                    order: state.order,
                    limit: LIMIT,
                    offset: (pageActive - 1) * LIMIT
                }
                state.queyList = QUERY_LIST
                state.pageActive = pageActive
                props.dispatch({ type: Types.TEMPLATE_LIST.REQUESTED, detail: template_query })
            }
        } else {
            props.dispatch({ type: Types.TEMPLATE_LIST.REQUESTED, detail: template_query })
        }
        return state;
    }

    /**
     * Handle remove item
     * @param {*} id 
     */
    handleRemove = (id) => {
        const detail = { id: id }
        this.props.dispatch({ type: Types.TEMPLATE_REMOVE.REQUESTED, detail })
    }

    /**
     * Search input form
     * @param {*} event 
     */
    handleSearch = (event) => {
        event.preventDefault()
        if (_.has(this.state, "searchText")) {
            const template = {
                name: _.get(this.state, "searchText"),
                type: _.get(this.state, "searchText"),
                comment: _.get(this.state, "searchText"),
                content: _.get(this.state, "searchText"),
                pageActive: INIT_PAGE
            }
            this.props.history.push({
                pathname: this.state.location.pathname
            })
            this.props.dispatch({ type: Types.TEMPLATE_LIST.REQUESTED, detail: template })
        }
    }

    /**
     * Handle change input search text
     * @param {*} event 
     */
    handleChange = (event) => {
        const searchText = event.target.value
        this.setState({
            searchText: searchText
        })
    }

    /**
     * Reset state search text empty
     */
    handleReset = () => {
        const resetText = ""
        this.setState({
            searchText: resetText,
            pageActive: INIT_PAGE
        })
        this.props.history.push({ pathname: this.state.location.pathname })
        this.props.dispatch({ type: Types.TEMPLATE_LIST.REQUESTED })
    }

    /**
     * Filter sortBy text
     * @param {*} sortBy 
     */
    handleSort = (sortBy) => {
        if (sortBy) {
            this.props.history.push({ pathname: this.state.location.pathname })
            this.getList(INIT_PAGE, sortBy, this.state.order)
        }
    }

    /**
     * Control item sortBy
     * @param {*} item 
     * @returns 
     */
    sortItem = (item) => {
        let itemDom = <IconChevronDown />
        if (item === this.state.sortBy) {
            if (this.state.order === SORT_ASC) {
                itemDom = <IconChevronUp />
            }
        }
        return itemDom
    }


    componentDidUpdate() {
        const { ListTemplate, CreateTemplate, UpdateTemplate, RemoveTemplate, ImportTemplate } = this.props.resultTemplate
        // Notification have errors
        if (_.get(ListTemplate,"error", false)) {
            NotificationManager.error(this.state.errorMessage, '', TIME_OUT)
            this.props.dispatch({ type: Types.TEMPLATE_LIST.RESET })
            this.setState({ error: null })
        }
        if (_.get(CreateTemplate, "error", false)) {
            NotificationManager.error(this.state.errorMessage, '', TIME_OUT)
            this.props.dispatch({ type: Types.TEMPLATE_CREATE.RESET })
            this.setState({ error: null })
        }
        if (_.get(UpdateTemplate, "error", false)) {
            NotificationManager.error(this.state.errorMessage, '', TIME_OUT)
            this.props.dispatch({ type: Types.TEMPLATE_UPDATE.RESET })
            this.setState({ error: null })
        }
        if (_.get(RemoveTemplate, "error", false)) {
            NotificationManager.error(this.state.errorMessage, '', TIME_OUT)
            this.props.dispatch({ type: Types.TEMPLATE_REMOVE.RESET })
            this.setState({ error: null })
        }
        if (_.get(ImportTemplate, "error", false)) {
            NotificationManager.error(this.state.errorMessage, '', TIME_OUT)
            this.props.dispatch({ type: Types.TEMPLATE_IMPORT.RESET })
            this.setState({ error: null })
        }

        // Notification actions success
        if (_.get(this.state, "successCreate", false)) {
            NotificationManager.success(CreateTemplate.message, '', TIME_OUT)
            this.props.dispatch({ type: Types.TEMPLATE_CREATE.RESET })
            this.setState({ successCreate: null })
        }

        if (_.get(this.state, "successUpdate", false)) {
            NotificationManager.success(UpdateTemplate.message, '', TIME_OUT)
            this.props.dispatch({ type: Types.TEMPLATE_UPDATE.RESET })
            this.setState({ successUpdate: null })
        }

        if (_.get(this.state, "successRemove", false)) {
            NotificationManager.success(RemoveTemplate.message, '', TIME_OUT)
            this.props.dispatch({ type: Types.TEMPLATE_REMOVE.RESET })
            this.setState({ successRemove: null })
        }

        if (_.get(this.state, "successImport", false)) {
            NotificationManager.success(ImportTemplate.message, '', TIME_OUT)
            this.props.dispatch({ type: Types.TEMPLATE_IMPORT.RESET })
            this.setState({ successImport: null })
        }
    }

    /* --------------------------------------------
            RENDERING
    -------------------------------------------- */
    render() {
        const { match } = this.props
        const { listTemp, pagination, location, isLoading, searchText } = this.state
        return (
            <React.Fragment>
                <Aside match={match} />
                <Header />
                <div className="page-wrapper mt-55">
                    <div className="page-body">
                        <div className="container-xl">
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title col">System Templates</h3>
                                            <div className="col-auto">
                                                <ModalImport dispatch={this.props.dispatch}/>
                                            </div>
                                        </div>
                                        <div className="card-body border-bottom py-3">
                                            <div className="d-flex">
                                                <Form onSubmit={this.handleSearch} className="ms-auto text-muted ms-3 d-inline-block me-3">
                                                    <div className="input-group input-group-flat">
                                                        <input type="text" name="search" className="form-control" placeholder="Search template…" onChange={this.handleChange} value={searchText} />
                                                        <span className="input-group-text">
                                                            {_.get(this.state, "searchText") && <Link className="link-secondary" to={"#"} title="Clear search" data-bs-toggle="tooltip" onClick={this.handleReset}><IconX /></Link>}
                                                            <Link className="link-secondary" to={"#"} onClick={this.handleSearch}><IconSearch /></Link>
                                                        </span>
                                                    </div>
                                                </Form>
                                                <ModalAdd dispatch={this.props.dispatch}/>
                                            </div>
                                        </div>
                                        <div className="table-responsive" style={{ minHeight: '300px' }}>
                                            <Table className="card-table table-vcenter text-nowrap datatable">
                                                <thead>
                                                    <tr>
                                                        <th>No <Link to={"#"} onClick={() => this.handleSort('id')}>{this.sortItem('id')}</Link></th>
                                                        <th>Name <Link to={"#"} onClick={() => this.handleSort('name')}>{this.sortItem('name')}</Link></th>
                                                        <th>Text <Link to={"#"} onClick={() => this.handleSort('content')}>{this.sortItem('content')}</Link></th>
                                                        <th>Created <Link to={"#"} onClick={() => this.handleSort('createdAt')}>{this.sortItem('createdAt')}</Link></th>
                                                        <th>Updated <Link to={"#"} onClick={() => this.handleSort('updatedAt')}>{this.sortItem('updatedAt')}</Link></th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(listTemp) && listTemp.length > 0 ? listTemp.map((row, i) =>
                                                        <tr key={i}>
                                                            <td><span className="text-muted">{"T-" + row.id}</span></td>
                                                            <td>{row.name} 
                                                                {row.count_category > 0 &&
                                                                <PopoverCategory tempId={row.id} countCategory={row.count_category} />}
                                                            </td>
                                                            <td>{row.content}</td>
                                                            <td>{formatDateString(row.createdAt)}</td>
                                                            <td>{formatDateString(row.updatedAt)}</td>
                                                            <td><span className="badge bg-success me-1"></span></td>
                                                            <td className={'td-action'}>
                                                                <ModalEdit 
                                                                    template={row}
                                                                    dispatch={this.props.dispatch} />
                                                                <ConfirmRemove
                                                                    title="Are you sure?"
                                                                    message="Do you really want to remove record."
                                                                    id={row.id}
                                                                    dispatch={this.props.dispatch} />
                                                            </td>
                                                        </tr>
                                                    ) : <NoData/> }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="card-footer d-flex align-items-center">
                                            {!_.isEmpty(pagination) &&
                                                <Paging
                                                    activePage={pagination.offset / LIMIT + 1}
                                                    itemsCountPerPage={pagination.limit || LIMIT}
                                                    totalItemsCount={pagination.totalCount}
                                                    pageRangeDisplayed={PAGE_DISPLAY}
                                                    requestList={this.getList}
                                                    location={location}
                                                    history={this.props.history}
                                                />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isLoading && <div className="loading">
                            <Spinner animation="border" role="status" />
                        </div>}
                    </div>
                    <Footer />
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    resultMe: state.me,
    resultAuth: state.auth,
    resultTemplate: state.template
})

export default connect(mapStateToProps)(Templates)