export const ATTRIBUTE_LIST = {
    REQUESTED: "ATTRIBUTE_LIST_REQUESTED",
    SUCCEEDED: "ATTRIBUTE_LIST_SUCCEEDED",
    FAILED: "ATTRIBUTE_LIST_FAILED",
    RESET: "ATTRIBUTE_LIST_RESET",
}

export const ATTRIBUTE_CREATE = {
    REQUESTED: "ATTRIBUTE_CREATE_REQUESTED",
    SUCCEEDED: "ATTRIBUTE_CREATE_SUCCEEDED",
    FAILED: "ATTRIBUTE_CREATE_FAILED",
    RESET: "ATTRIBUTE_CREATE_RESET",
}

export const ATTRIBUTE_UPDATE = {
    REQUESTED: "ATTRIBUTE_UPDATE_REQUESTED",
    SUCCEEDED: "ATTRIBUTE_UPDATE_SUCCEEDED",
    FAILED: "ATTRIBUTE_UPDATE_FAILED",
    RESET: "ATTRIBUTE_UPDATE_RESET",
}

export const ATTRIBUTE_DETAIL = {
    REQUESTED: "ATTRIBUTE_DETAIL_REQUESTED",
    SUCCEEDED: "ATTRIBUTE_DETAIL_SUCCEEDED",
    FAILED: "ATTRIBUTE_DETAIL_FAILED",
    RESET: "ATTRIBUTE_DETAIL_RESET",
}

export const ATTRIBUTE_REMOVE = {
    REQUESTED: "ATTRIBUTE_REMOVE_REQUESTED",
    SUCCEEDED: "ATTRIBUTE_REMOVE_SUCCEEDED",
    FAILED: "ATTRIBUTE_REMOVE_FAILED",
    RESET: "ATTRIBUTE_REMOVE_RESET",
}