import React from "react";

/**
 * 
 * @returns Empty data
 */
const Empty = () => (
    <tr>
        <td colSpan={8} className="text-center">
            <div className="empty">
                <div className="empty-icon" />
                <p className="empty-title">No results found</p>
                <p className="empty-subtitle text-muted">
                    Try adjusting your search or filter to find what you're looking for.
                </p>
            </div>
        </td>
    </tr>
);

export default React.memo(Empty);
