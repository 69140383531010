import fetch from 'isomorphic-fetch';
import * as Utils from '../../utils';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;

export const getListCategory = async (params) => {
    const response = await fetch(`${BASE_URL}/categories?${Utils.ObjectToQueryString(params)}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const postCategory = async (data) => {
    const response = await fetch(`${BASE_URL}/categories`, {
        method: 'POST',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    });
    return Utils.handleNon200s(response);
}

export const putCategory = async (data) => {
    const response = await fetch(`${BASE_URL}/categories/${data.id}`, {
        method: 'PUT',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    });
    return Utils.handleNon200s(response);
}

export const getCategory = async (params) => {
    const response = await fetch(`${BASE_URL}/categories/${params.id}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const deleteCategory = async (params) => {
    const response = await fetch(`${BASE_URL}/categories/${params.id}`, {
        method: 'DELETE',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}