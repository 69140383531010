export const CSV_LIST = {
    REQUESTED: "CSV_LIST_REQUESTED",
    SUCCEEDED: "CSV_LIST_SUCCEEDED",
    FAILED: "CSV_LIST_FAILED",
    RESET: "CSV_LIST_RESET",
}

export const CSV_CREATE = {
    REQUESTED: "CSV_CREATE_REQUESTED",
    SUCCEEDED: "CSV_CREATE_SUCCEEDED",
    FAILED: "CSV_CREATE_FAILED",
    RESET: "CSV_CREATE_RESET",
}

export const CSV_REMOVE = {
    REQUESTED: "CSV_REMOVE_REQUESTED",
    SUCCEEDED: "CSV_REMOVE_SUCCEEDED",
    FAILED: "CSV_REMOVE_FAILED",
    RESET: "CSV_REMOVE_RESET",
}

export const CSV_EXPORT = {
    REQUESTED: "CSV_EXPORT_REQUESTED",
    SUCCEEDED: "CSV_EXPORT_SUCCEEDED",
    FAILED: "CSV_EXPORT_FAILED",
    RESET: "CSV_EXPORT_RESET",
}