import React, { Component } from 'react'
import { IconAlertTriangle, IconCircleMinus } from '@tabler/icons'
import { Modal, Form, Button } from 'react-bootstrap'

import * as Types from '../actions'

class ConfirmRemove extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.dispatch({ type: Types.CATEGORY_REMOVE.REQUESTED, detail: {id: this.props.id} })
        this.toggle();
    }

    render() {
        const {title, message} = this.props;
        return (
            <React.Fragment>
                <span className="text-danger cl-pointer" onClick={this.toggle}><IconCircleMinus /></span>
                <Modal show={this.state.isOpen} onHide={this.toggle} className="modal-blur" size="sm" centered>
                    <Form onSubmit={this.handleSubmit}>
                        <button type="button" className="btn-close" onClick={this.toggle}></button>
                        <div className="modal-status bg-danger"></div>
                        <div className="modal-body text-center py-4">
                            <IconAlertTriangle className="mb-2 text-danger icon-lg" />
                            <h3>{title}</h3>
                            <div className="text-muted">{message}</div>
                        </div>
                        <div className="modal-footer">
                            <div className="w-100">
                                <div className="row">
                                    <div className="col">
                                        <Button variant="white w-100" onClick={this.toggle}>Cancel</Button>
                                    </div>
                                    <div className="col">
                                        <Button variant="danger w-100" type="submit">Delete</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ConfirmRemove;