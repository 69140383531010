import React, { Component } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { IconCloudUpload, IconRefresh, IconX } from '@tabler/icons'
import { CSVReader } from 'react-papaparse'
import * as Types from './actions'

const buttonRef = React.createRef()

class ModalImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileName: "",
            column: [],
            formData: [],
            csvFile: {},
            isOpen: false,
            isLoading: false,
            isError: false,
            errors: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.importSuccess) {
            state.isOpen = false
            state.isLoading = false
        }
        return state;
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
            formData: [],
            errors: [],
            fileName: ""
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { fileName, formData } = this.state
        const csv = { fileName, formData }
        this.props.dispatch({ type: Types.TEMPLATE_IMPORT.REQUESTED, detail: csv })
        this.toggle();
    }

    handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }

    handleOnFileLoad = (data, csvFile) => {
        let { errors } = this.state
        const { name } = csvFile
        const pattern = "^.+\\.(csv)$"
        const regex = new RegExp(pattern)
        if (regex.test(name)) {
            this.setState({
                errors: [],
                fileName: name,
                formData: data
            })
            console.log("data => ", data)
        } else {
            errors.push("Import has been accept only csv file");
            this.setState({ errors })
        }
    }

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }

    handleOnRemoveFile = (data) => {
        this.setState({ errors: [], formData: data, csvFile: {} })
    }

    handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e)
        }
    }

    render() {
        const { isOpen } = this.state
        return (
            <React.Fragment>
                <Button className="pull-right" onClick={this.toggle}>
                    <IconCloudUpload />Import
                </Button>
                <Modal show={isOpen} onHide={this.toggle} className="modal-blur" centered size="lg">
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Import templates</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CSVReader
                                accept={'.csv, text/csv'}
                                ref={buttonRef}
                                onFileLoad={this.handleOnFileLoad}
                                onError={this.handleOnError}
                                noClick
                                noDrag
                                onRemoveFile={this.handleOnRemoveFile}
                            >
                                {({ file }) => (
                                    <aside style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                        <Button
                                            type='button'
                                            className="btn-outline-primary"
                                            onClick={this.handleOpenDialog}
                                            style={{ borderRadius: 0, width: '40%' }}>
                                            <IconCloudUpload /> Browse file .csv
                                        </Button>
                                        <div style={{
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderColor: '#ccc',
                                            lineHeight: 2.5,
                                            paddingLeft: '10px',
                                            width: '60%'
                                        }}>
                                            {file && file.name}
                                        </div>
                                        <Button
                                            type="button"
                                            className="btn-outline-secondary"
                                            style={{ borderRadius: 0 }}
                                            onClick={this.handleRemoveFile}><IconRefresh style={{ margin: '0px' }} /></Button>
                                    </aside>
                                )}
                            </CSVReader>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link-secondary" onClick={this.toggle}><IconX/>Cancel</Button>
                            <Button variant="primary ms-auto" type="submit"><IconCloudUpload />Import</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalImport