import * as ActionTypes from './actions';

const defaultState = {
    message: null,
    inProgress: false,
    done: false,
    error: false
};

const authReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.POST_AUTH_REQUESTED:
            return {
                ...state,
                inProgress: true,
                done: false,
                error: false,
                message: 'Loading',
            };
        case ActionTypes.POST_AUTH_SUCCEEDED:
            return {
                ...state,
                detail: action,
                inProgress: false,
                done: true,
                error: false,
                message: 'Success'
            }
        case ActionTypes.POST_AUTH_FAILED:
            return {
                ...state,
                inProgress: false,
                done: true,
                error: true,
                message: action.error
            }
        default:
            return state;
    }
};

export default authReducer;