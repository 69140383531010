/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconMessage2, IconMail, IconWorldWww, IconZip, IconMapPin } from '@tabler/icons';
import { IoToggle, IoToggleOutline } from 'react-icons/io5';
import { Modal, Button } from 'react-bootstrap';
import { formatDateString } from '../../utils';

const ShowUser = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    return <>
        <span className='text-primary me-2 cl-pointer' onClick={toggle}><IconMessage2 /></span>
        <Modal show={isOpen} onHide={toggle} className='modal-blur' size='sm' centered>
            <div>
                <button type='button' className='btn-close' onClick={toggle}></button>
                <div className='modal-body py-4'>
                    <h3 className='text-center'>{data.email}</h3>
                    <div className='text-muted'>
                        {<span className='avatar'>{data.first_name && data.first_name.charAt(0) + '' + data.last_name && data.last_name.charAt(0)}</span>}
                        {data.email && <p><IconMail />: {data.email}</p>}
                        {data.entityId && <p>{data.entityId.name}</p>}
                        {data.role && <p><code>
                            {data.role === 4 && 'Super Admin'}
                            {data.role === 3 && 'Admin'}
                            {data.role === 2 && 'Assistant'}
                            {data.role === 1 && 'User'}
                        </code></p>}
                        {data.number_phone && <p>Phone number: {data.number_phone}</p>}
                        {data.zipcode && <p><IconZip />: {data.zipcode}</p>}
                        {data.address && <p><IconMapPin />: {data.address}</p>}
                        {data.description && <p>{data.description}</p>}
                        {<p>{data.status ? <IoToggle size={25} color='green' /> : <IoToggleOutline size={25} color='gray' />}</p>}
                        {data.createdAt && <p>{formatDateString(data.createdAt)}</p>}
                    </div>
                </div>
                <div className='modal-footer'>
                    <div className='w-100'>
                        <div className='row'>
                            <div className='col'>
                                <Button variant='white w-100' onClick={toggle}>Close</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default ShowUser;