import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import routeConfig from '../../routes';

import ErrorPageLayout from '../Layout/ErrorPageLayout';
import GenericNotFound from './GenericNotFound';
import Forbidden from './Forbidden';


/**
 * Switch to all router configuration
 */
const MainRouter = () => {
    return (
        <Switch>
            {routeConfig.map((route, index) => {
                const { path } = route;
                return (
                    <route.layout
                        key={index}
                        exact
                        path={path}
                        render={matchprops => (
                            <Suspense fallback={<Spinner />}>
                                <route.component {...matchprops} />
                            </Suspense>
                        )}
                    />
                )
            })}
            <Route path="/forbidden" component={Forbidden} layout={ErrorPageLayout} />
            <Route component={GenericNotFound} layout={ErrorPageLayout} />
        </Switch>
    )
}

export default MainRouter;