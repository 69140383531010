import React, { Component } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { IconEditCircle, IconDownload, IconX } from '@tabler/icons'

import * as Types from './actions'

class ModalEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false
        }
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const name = event.target.getElementsByTagName('input')['name'].value
        const category_code = event.target.getElementsByTagName('input')['category_code'].value
        const comment = event.target.getElementsByTagName('input')['comment'].value
        const content = event.target.getElementsByTagName('textarea')['content'].value

        const template = {
            id: this.props.template.id,
            name, category_code, comment, content
        }
        this.props.dispatch({ type: Types.TEMPLATE_UPDATE.REQUESTED, detail: template })
        this.toggle()
    }

    render() {
        const { template } = this.props
        return (
            <React.Fragment>
                <span onClick={this.toggle} className="text-primary me-2 cl-pointer"><IconEditCircle /></span>
                <Modal show={this.state.isOpen} onHide={this.toggle} className="modal-blur" centered size="lg" >
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit template</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label required">Name</label>
                                <input id="name" type="text" className="form-control" name="name" placeholder="Name" defaultValue={template.name} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="category_code" className="form-label">Category code</label>
                                <input id="category_code" type="text" className="form-control" name="category_code" placeholder="Category code" defaultValue={template.category_code} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="comment" className="form-label">Comment</label>
                                <input id="comment" type="text" className="form-control" name="comment" placeholder="Comment" defaultValue={template.comment} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="content" className="form-label required">Text</label>
                                <textarea id="content" type="text" className="form-control" name="content" rows="6" placeholder="Content" defaultValue={template.content} ></textarea>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link-secondary" onClick={this.toggle}><IconX/>Cancel</Button>
                            <Button variant="primary ms-auto" type="submit"><IconDownload />Save</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </React.Fragment>
        )
    }
}

export default ModalEdit