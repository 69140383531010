/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Form, Button } from 'react-bootstrap';
import { IconCloudUpload, IconRefresh, IconX } from '@tabler/icons';
import { CSVReader } from 'react-papaparse';
import _ from 'lodash';
import { ButtonClipLoader } from '../../components/Loading';
import { postProduct, getProducts } from './services';

const buttonRef = React.createRef()

const ImportProduct = () => {
    let history = useHistory();

    const [isOpen, setIsOpen] = useState(false);
    const [description, setDescription] = useState(true);
    const [metaDescription, setMetaDescription] = useState(true);
    const [metaTitle, setMetaTitle] = useState(true);
    const [metaKeyword, setMetaKeyword] = useState(true);
    const [fileName, setFileName] = useState('');
    const [formData, setFormData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState([]);

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const handleChange = (e) => {
        const name = e.target.name
        switch (name) {
            case 'description':
                setDescription(e.target.checked)
                break;
            case 'metaDescription':
                setMetaDescription(e.target.checked)
                break;
            case 'metaTitle':
                setMetaTitle(e.target.checked)
                break;
            case 'metaKeyword':
                setMetaKeyword(e.target.checked)
                break;
            default:
                break;
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const csv = { fileName, formData, description, metaDescription, metaTitle, metaKeyword };
        if (!_.isNull(formData)) {
            console.log('hand submit csv: ', csv);
            const textgen = {
                description,
                meta_description: metaDescription,
                meta_title: metaTitle,
                meta_keyword: metaKeyword
            };
            const dataHeaders = [];
            const dataContent = [...formData.slice(1)];
            const products = [];
            _.each(formData[0].data, (value) => {
                dataHeaders.push(_.lowerCase(value));
            });

            _.each(dataContent, (value) => {
                const attributes = [];
                value.data.map((value, key) => {
                    if (key >0) {
                        attributes.push({
                            label: dataHeaders[key],
                            value: _.trim(value)
                        });
                    }
                })
                products.push({
                    sku: _.trim(value.data[0]),
                    attributes: attributes,
                });
            });
            console.log('products: ', products);
            const params = {
                textgen,
                products
            };
            const result = await postProduct(params);
            if (result) {
                setIsLoading(false);
                toggle();
                history.push('/products');
            }
            
        } else {
            setIsLoading(false);
        }
    }
    const handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    }

    const handleOnFileLoad = (data, csvFile) => {
        const pattern = "^.+\\.(csv)$"
        const regex = new RegExp(pattern)
        if (regex.test(csvFile.name)) {
            setErrors([]);
            setFileName(csvFile.name);
            setFormData(data);
            console.log("data => ", data)
        } else {
            setErrors('Import has been accept only csv file');
        }
    }

    const handleOnError = (err) => {
        console.log(err)
    }

    const handleOnRemoveFile = (data) => {
        console.log('handleOnRemoveFile: ', data);
        setErrors([]);
        setFormData(data);
    }

    const handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e)
        }
    }

    return <>
        <Button className="pull-right me-2" onClick={toggle}>
            <IconCloudUpload />Import
        </Button>
        <Modal show={isOpen} onHide={toggle} className="modal-blur" centered size="lg">
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Import</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-table'>
                        <label className='form-check'>
                            <input className='form-check-input' type='checkbox' name='description' defaultChecked={description} onChange={handleChange} />
                            <span className='form-check-label'>Description</span>
                        </label>
                        <label className='form-check'>
                            <input className='form-check-input' type='checkbox' name='metaDescription' defaultChecked={metaDescription} onChange={handleChange} />
                            <span className='form-check-label'>Meta Description</span>
                        </label>
                        <label className='form-check'>
                            <input className='form-check-input' type='checkbox' name='metaTitle' defaultChecked={metaTitle} onChange={handleChange} />
                            <span className='form-check-label'>Meta Title</span>
                        </label>
                        <label className='form-check'>
                            <input className='form-check-input' type='checkbox' name='metaKeyword' defaultChecked={metaKeyword} onChange={handleChange} />
                            <span className='form-check-label'>Meta Keyword</span>
                        </label>
                    </div>
                    <CSVReader
                        accept={'.csv, text/csv'}
                        ref={buttonRef}
                        onFileLoad={handleOnFileLoad}
                        onError={handleOnError}
                        noClick
                        noDrag
                        onRemoveFile={handleOnRemoveFile}
                    >
                        {({ file }) => (
                            <aside style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                <Button
                                    type='button'
                                    className='btn-outline-primary'
                                    onClick={ handleOpenDialog }
                                    style={{ borderRadius: 0, width: '40%' }}>
                                    <IconCloudUpload/> Browse file .csv
                                </Button>
                                <div style={{
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor: '#ccc',
                                    lineHeight: 2.5,
                                    paddingLeft: '10px',
                                    width: '60%'
                                }}>
                                    {file && file.name}
                                </div>
                                <Button
                                    type='button'
                                    className='btn-outline-secondary'
                                    style={{ borderRadius: 0 }}
                                    onClick={handleRemoveFile}><IconRefresh style={{ margin: '0px' }} /></Button>
                            </aside>
                        )}
                    </CSVReader>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='link-secondary' onClick={toggle}><IconX />Cancel</Button>
                    <ButtonClipLoader
                        disabled={false}
                        variant='primary'
                        icon={<IconCloudUpload />}
                        isLoading={isLoading}
                        type='submit'
                        label='Import'
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}

export default ImportProduct;