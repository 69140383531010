import fetch from 'isomorphic-fetch';
import * as Utils from '../../utils';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;

export const getProfile = async () => {
    const response = await fetch(`${BASE_URL}/auth/me`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
};

export const putProfile = async (data) => {
    const response = await fetch(`${BASE_URL}/users/profile`, {
        method: 'PUT',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    });
    return Utils.handleNon200s(response);
};

export const getAvatar = async () => {
    const response = await fetch(`${BASE_URL}/me/avatar`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return response;
};
