import React from 'react';
import { Link } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons';

/**
 *  Setup template page Page not found
 *  URL uncorrect partern router config
 */
class GenericNotFound extends React.Component {
    render() {
        return (
            <div className="page page-center">
                <div className="container-tight py-4">
                    <div className="empty">
                        <div className="empty-header">404</div>
                        <p className="empty-title">Oops… You just found an error page</p>
                        <p className="empty-subtitle text-muted">
                            Cette ressource est introuvable
                        </p>
                        <div className="empty-action">
                            <Link to={"/"} className="btn btn-primary">
                                <IconArrowLeft/>
                                Retour à l'accueil
                                {/* Take me home */}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GenericNotFound;