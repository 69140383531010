import {combineReducers} from 'redux';
import { ListTemplate, DetailTemplate, CreateTemplate, UpdateTemplate, RemoveTemplate, ImportTemplate, CountCategory } from './reducers';

export default combineReducers({
    ListTemplate: ListTemplate,
    DetailTemplate: DetailTemplate,
    CreateTemplate: CreateTemplate,
    UpdateTemplate: UpdateTemplate,
    RemoveTemplate: RemoveTemplate,
    ImportTemplate: ImportTemplate,
    CountCategory: CountCategory
})