import React from "react";
import { Route } from 'react-router-dom';

const ErrorLayout = (props) => {
    const { children } = props;
    return (
        <div className="page page-center">
            {children}
        </div>
    );
};

const ErrorPageLayout = ({ render, ...rest }) => {
    return (
        <Route
            {...rest}
            render={matchProps => (
                <ErrorLayout>
                    {render(matchProps)}
                </ErrorLayout>
            )}
        />
    );
};


export default ErrorPageLayout;