/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Toast from 'react-bootstrap/Toast';
import _ from 'lodash';
import { IconEditCircle, IconX, IconDownload, IconEyeOff, IconEye, IconAlertTriangle } from '@tabler/icons';
import { putUser } from './services';
import { PASSWORD_LENGTH } from '../../constant';

const UpdateUser = ({ user, userInfo, fetchDataUser, entities }) => {
    const data = _.pickBy(user, v => !_.isNull(v) && !_.isUndefined(v));
    const email = _.get(data, 'email', '');
    const [eye, setEye] = useState(true);
    const [show, setShow] = useState(false);
    const [firstName, setFirstName] = useState(_.get(data, 'first_name', ''));
    const [lastName, setLastName] = useState(_.get(data, 'last_name', ''));
    const [entityId, setEntityId] = useState(data.entityId.id);
    const [role, setRole] = useState(_.get(data, 'role', ''));
    const [address, setAddress] = useState(_.get(data, 'address', ''));
    const [numberPhone, setNumberPhone] = useState(_.get(data, 'number_phone', ''));
    const [zipcode, setZipcode] = useState(_.get(data, 'zipcode', ''));
    const [status, setStatus] = useState(_.get(data, 'status', ''));
    const [description, setDescription] = useState(_.get(data, 'description', ''));
    const [password, setPassword] = useState(_.get(data, 'password', ''));
    const [errors, setErrors] = useState([]);
    const [errResponse, setErrResponse] = useState(false);
    const [mesResponse, setMesResponse] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'entityId':
                setEntityId(value);
                break;
            case 'role':
                setRole(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'numberPhone':
                setNumberPhone(value);
                break;
            case 'zipcode':
                setZipcode(value);
                break;
            case 'status':
                setStatus(e.target.checked);
                break;
            case 'description':
                setDescription(value);
                break;
            default:
                break;
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isChecked = checkValidity();
        if (isChecked) {
            let dataUser = {
                id: data.id,
                email: email,
                entityId: Number(entityId),
                role: Number(role),
                first_name: firstName,
                last_name: lastName,
                number_phone: numberPhone,
                address: address,
                status: status,
                zipcode: zipcode,
                description: description,
                password: password
            }
            dataUser = _.pickBy(dataUser, (v, key) => {
                if (_.includes(['status', 'role', 'entityId', 'id'], key)) {
                    return !_.isNull(v) && !_.isUndefined(v)
                } else {
                    return !_.isEmpty(v) && !_.isNull(v) && !_.isUndefined(v)
                }
            });
            const response = await putUser(dataUser);
            if (response && _.has(response, 'data')) {
                fetchDataUser();
                setShow(false);
            } else {
                setErrors([{ name: 'response', message: response.errMessage}]);
                setErrResponse(true);
                setMesResponse(response.errMessage)
            }
        }
    }
    const checkValidity = () => {
        let isValidated = true;
        let tmpErrors = [...errors];
        if (!_.isEmpty(password) && !_.isUndefined(password) && !_.isNull(password)) {
            if (!password || password.length < PASSWORD_LENGTH.MIN || password.length > PASSWORD_LENGTH.MAX) {
                const err = tmpErrors.filter((item) => item.name === 'password');
                if (!_.size(err)) {
                    tmpErrors = tmpErrors.concat([{ name: 'password', message: '' }]);
                }
                isValidated = false;
            }
        }
        setErrors(tmpErrors);
        return isValidated;
    }
    const EyeElement = () => {
        let domShow = <span className="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={() => setEye(false)}><IconEyeOff /></span>;
        if (!eye) {
            domShow = <span className="link-secondary" title="Hidden password" data-bs-toggle="tooltip" onClick={() => setEye(true)}><IconEye /></span>;
        }
        return domShow;
    }
    return (
        <>
            <span className='text-primary me-2 cl-pointer' onClick={handleShow}><IconEditCircle /></span>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Update User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className='mb-3' controlId='email'>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type='email'
                                autoComplete='email'
                                autoFocus
                                value={email}
                                name='email'
                                readOnly 
                                disabled
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='password'>
                            <Form.Label>Password<span className='required ms-1'>(*)</span></Form.Label>
                            <InputGroup>
                                <Form.Control
                                    className={_.size(errors.filter((item) => item.name === 'password')) ? 'is-invalid' : ''}
                                    type={eye ? "password" : "text"}
                                    autoFocus
                                    autoComplete='new-password'
                                    value={password}
                                    name='password'
                                    onChange={handleChange}
                                />
                                <InputGroup.Text className='cl-pointer'>
                                    {EyeElement()}
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='entityId'>
                            <Form.Label>Entity<span className='required ms-1'>(*)</span></Form.Label>
                            <Form.Select name='entityId' onChange={handleChange} defaultValue={entityId}>
                                {Array.isArray(entities) && entities.map((e, k) =>
                                    <option key={k} value={e.id}>{e.name}</option>
                                )}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='firstName'>
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                value={firstName}
                                name='firstName'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='lastName'>
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                value={lastName}
                                name='lastName'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='role'>
                            <Form.Label>Role</Form.Label>
                            <Form.Select name='role' onChange={handleChange} defaultValue={user.role}>
                                {userInfo.role >= 1 && <option value={1}>User</option>}
                                {userInfo.role >= 2 && <option value={2}>Assistant</option>}
                                {userInfo.role >= 3 && <option value={3}>Admin</option>}
                                {userInfo.role === 4 && <option value={4}>Super Admin</option>}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='numberPhone'>
                            <Form.Label>Phone number</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                value={numberPhone}
                                name='numberPhone'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='zipcode'>
                            <Form.Label>ZIP Code</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                value={zipcode}
                                name='zipcode'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Switch
                            className='mb-3'
                            id='status'
                            type="switch"
                            autoFocus
                            checked={status}
                            name='status'
                            onChange={handleChange}
                            label='Status'
                        />
                        <Form.Group className='mb-3' controlId='description'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as='textarea'
                                rows={3}
                                value={description}
                                name='description'
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                    <Toast show={errResponse} className="alert alert-warning alert-dismissible" role="alert">
                        <div className="d-flex">
                            <div><IconAlertTriangle className="alert-icon" /></div>
                            <div>
                                <h4 className="alert-title">{'Something went wrong'}</h4>
                                <div className="text-muted">{mesResponse}</div>
                            </div>
                        </div>
                        <a className="btn-close" onClick={() => setErrResponse(false)} />
                    </Toast>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='default' type='button' onClick={handleClose}><IconX />Cancel</Button>
                    <Button variant='primary ms-auto' type='submit' onClick={handleSubmit}><IconDownload />Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UpdateUser;