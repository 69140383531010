import React, { Component } from "react";
import { connect } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import Select from 'react-select'
import { IconDatabaseExport, IconFileDownload, IconFileText } from '@tabler/icons';
import { CSVLink } from "react-csv";
import _ from 'lodash';

import * as TypeTemplates from '../Templates/actions'
import * as Types from './actions';
import { formatDateString } from '../../utils';
class ModalExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            templates: props.templates,
            csvId: props.csv.id,
            templateId: (Array.isArray(props.templates) && props.templates.length > 0) ? props.templates[0].id : null,
            errors: []
        }

        this.csvLinkEl = React.createRef();
        this.toggle = this.toggle.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { resultExport } = props;
        const { ExportCsv } = resultExport;

        // Export CSV
        const exportFile = _.get(ExportCsv, 'detail.result', null)
        if (ExportCsv.error) {
            state.isLoading = false;
            state.error = true;
            state.errorMessage = ExportCsv.message;
        }
        if (_.has(exportFile, 'data')) {
            state.contentCsv = exportFile.data
            state.templateData = exportFile.templateData

            const contentCsv = exportFile.data
            const templateData = exportFile.templateData
            const contentTemplate = templateData.content;

            _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
            const dataType = [["category", "text"]];
            for (let index = 0; index < contentCsv.length; index++) {
                const contentElement = JSON.parse(contentCsv[index].content);
                var compiled = _.template(contentTemplate);
                try {
                    dataType.push([ templateData.type, compiled(contentElement)]);
                } catch (error) {
                    state.errors.push(_.replace(error, "ReferenceError:", ""))
                }
            }
            if (dataType.length > contentCsv.length) {
                state.successExport = true;
                state.dataType = dataType;
            }
        }
        return state;
    }

    toggle = () => {
        this.props.dispatch({ type: Types.CSV_EXPORT.RESET })
        this.setState({
            isOpen: !this.state.isOpen,
            errors: []
        })
    }

    // getListTemplate = () => {
    //     const QUERY_LIST = {
    //         sortBy: 'createdAt',
    //         order: 'DESC',
    //         limit: 1000,
    //         offset: 0
    //     }
    //     this.props.dispatch({ type: TypeTemplates.TEMPLATE_LIST.REQUESTED, detail: QUERY_LIST })
    // }

    handleSubmit = (event) => {
        event.preventDefault();
        const { csvId, templateId } = this.state;
        const exportCsv = { csvId, templateId };
        this.props.dispatch({ type: Types.CSV_EXPORT.REQUESTED, detail: exportCsv })
    }

    handleChangeInput = (inputValue) => {
        if(inputValue) {
            const templateId = parseInt(inputValue.value);
            this.setState({ templateId: templateId });
        }
        this.props.dispatch({ type: Types.CSV_EXPORT.RESET })
        this.setState({ errors: []})
    }

    componentDidUpdate() {
        const { successExport } = this.state
        if (successExport) {
            if(_.get(this.csvLinkEl.current, 'link', null)) {
                setTimeout(() => {
                    this.csvLinkEl.current.link.click()
                }, 1000);
            }
            this.props.dispatch({ type: Types.CSV_EXPORT.RESET })
            this.setState({ successExport: null })
        }
    }

    render() {
        const { csv } = this.props;
        let { templates, errors, dataType, templateData } = this.state;
        const dataOptions = [];
        if (Array.isArray(templates) && templates.length > 0 && templates.map((row, _i) =>
            dataOptions.push({value: row.id, label:  row.name + " - " + formatDateString(row.createdAt)})
        ))
        return (
            <React.Fragment>
                <span className="cl-pointer ms-2 text-primary" onClick={this.toggle}><IconDatabaseExport /></span>
                <Modal show={this.state.isOpen} onHide={this.toggle} className="modal-blur" centered size="lg" >
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Export CSV</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-3 row">
                                <div className="col-lg-6">
                                    <label htmlFor="csv" className="form-label">File Csv <IconFileText /></label>
                                    <input id="csv" type="text" className="form-control" defaultValue={csv.file_name + " - " + formatDateString(csv.createdAt)} disabled />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="template" className="form-label">Template</label>
                                    {Array.isArray(dataOptions) && dataOptions.length > 0 &&
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={dataOptions[0]}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="template"
                                        onChange={this.handleChangeInput}
                                        options={dataOptions}
                                        />}
                                </div>
                            </div>
                            {Array.isArray(errors) && errors.length > 0 &&
                            <div className="mb-3 row text-danger errors">
                                <h4>Export Fail</h4>
                                {errors.map((row, i) => (
                                    <p key={i}>Line {i + 2} {row} in data file {csv.file_name}</p>
                                ))}
                            </div>}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link-secondary" onClick={this.toggle}>Cancel</Button>
                            <Button variant="primary ms-auto" type="submit"><IconFileDownload />Export</Button>
                            {dataType && <CSVLink 
                                filename={templateData.name + "-" + templateData.id + ".csv"}
                                data={dataType}
                                ref={this.csvLinkEl} />}
                        </Modal.Footer>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    resultExport: state.exportCsv
})

export default connect(mapStateToProps)(ModalExport);