/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { IconAlertTriangle, IconCircleMinus } from '@tabler/icons';
import { Modal, Form, Button } from 'react-bootstrap';
import _ from 'lodash';
import { deleteUser } from './services';

const ConfirmRemove = ({ id, title, message, fetchDataUser }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!_.isUndefined(id)) {
            const resultRemove = await deleteUser({ id });
            if (resultRemove) {
                fetchDataUser();
            }
        }
        toggle();
    }
    return <>
        <span className='text-danger cl-pointer' onClick={toggle}><IconCircleMinus /></span>
        <Modal show={isOpen} onHide={toggle} className='modal-blur' size='sm' centered>
            <Form onSubmit={handleSubmit}>
                <button type='button' className='btn-close' onClick={toggle}></button>
                <div className='modal-status bg-danger'></div>
                <div className='modal-body text-center py-4'>
                    <IconAlertTriangle className='mb-2 text-danger icon-lg' />
                    <h3>{title}</h3>
                    <div className='text-muted'>{message}</div>
                </div>
                <div className='modal-footer'>
                    <div className='w-100'>
                        <div className='row'>
                            <div className='col'>
                                <Button variant='white w-100' type='button' onClick={toggle}>Cancel</Button>
                            </div>
                            <div className='col'>
                                <Button variant='danger w-100' type='submit'>Delete</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    </>
}

export default ConfirmRemove;