import fetch from 'isomorphic-fetch';
import * as Utils from '../../utils';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;

export const getListCsv = async (params) => {
    const response = await fetch(`${BASE_URL}/imports?${Utils.ObjectToQueryString(params)}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const postCsv = async (data) => {
    const response = await fetch(`${BASE_URL}/imports`, {
        method: 'POST',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    });
    return Utils.handleNon200s(response);
}

export const deleteCsv = async (params) => {
    const response = await fetch(`${BASE_URL}/imports/${params.id}`, {
        method: 'DELETE',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const exportCsv = async (params) => {
    const response = await fetch(`${BASE_URL}/exports/${params.csvId}/${params.templateId}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}