/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
// import _ from 'lodash';
import fetch from 'isomorphic-fetch';
import { IconX, IconDownload } from '@tabler/icons';
import ImageUpload from 'react-image-easy-upload';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import toast, { Toaster } from 'react-hot-toast';
import { getAvatar } from './services';
import { commonHeaders, Authorization, logout } from '../../utils';
import * as Storage from '../../storage'
import imageAvatar from '../../assets/img/avatar/avatar-girl-svgrepo-com.svg';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;

const ModalImage = () => {
    const userInfo = JSON.parse(localStorage.getItem(Storage.USER_INFO));
    const [avatarURL, setAvatarURL] = useState(imageAvatar);
    const [uploadedImage, setUploadedImage] = useState(avatarURL);
    const [uploadProgress, setUploadProgress] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const styleUpload = {
        backgroundImage: `url(${avatarURL})`,
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: ' padding-box',
        backgroundSize: '100%',
        position: 'relative',
        minWidth: '200px',
        minHeight: '200px'
    };

    useEffect(() => {
        fetch(`${BASE_URL}/me/avatar`, {
            method: 'GET',
            headers: commonHeaders(),
        }).then((response) => {
            const responseOK = response.ok;
            if (responseOK) {
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    },
                });
            } else {
                throw new Error('No avatar');
            }
        })
        .then((stream) => new Response(stream))
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
            setAvatarURL(url);
            setUploadedImage(url);
        })
        .catch((err) => console.error(err));
    }, [success]);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        // setShow(false);
        upload(uploadedImage);
    }

    /**
     * Upload file image
     */
    const upload = (uploadedImage) => {
        const url = `${BASE_URL}/users/avatar`;
        let xhr = new XMLHttpRequest();
        var formData = new FormData();
        formData.append('image', uploadedImage);
        xhr.upload.onprogress = function (event) {
            setUploadProgress(parseInt((event.loaded / event.total) * 100));
            console.log('UploadProgress: ', parseInt((event.loaded / event.total) * 100));
        };
        xhr.onloadend = function () {
            if (xhr.status === 401) {
                // auto logout if 401 response returned from api
                setTimeout(function () {
                    logout();
                }, 3000);
            }
            if (xhr.status === 403 || xhr.status === 404) {
                setLoading(false);
                toast.error('Image server error');
                // window.location.replace("/forbidden")
            } else {
                if (xhr.status === 200) {
                    setSuccess(true);
                    setShow(false);
                } else if (xhr.status >= 400) {
                    setUploadProgress(null)
                    toast.error(xhr.responseText);
                    setLoading(false);
                }
                setLoading(false);
            }
        };
        xhr.open("POST", url);
        xhr.setRequestHeader('Authorization', Authorization());
        xhr.send(formData);
    }
    return (<>
        <div className='image-upload' onClick={handleShow} style={styleUpload}></div>
        <div><Toaster /></div>
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Change avatar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} className='row'>
                    <Form.Group controlId="formFile" className="text-center">
                        <div className='image-upload' style={styleUpload} >
                            <ImageUpload
                                setImage={setUploadedImage}
                                defaultValue={avatarURL}
                                width="200px"
                                height="200px"
                                shape="thumbnail"
                                border={true}
                            />
                        </div>
                        {uploadedImage && uploadedImage.name ? <p>{uploadedImage.name}</p> : <></>}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='default' type='button' onClick={handleClose}><IconX />Cancel</Button>
                <Button variant='primary ms-auto' type='submit' onClick={handleSubmit}><IconDownload />Save</Button>
            </Modal.Footer>
        </Modal>
        <Toaster
            position="top-center"
            reverseOrder={false}
        />
    </>);
}

export default ModalImage;