import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import * as ActionTypes from '../../actions/types'
import * as Types from './actions'
import Aside from '../../components/Aside'
import Header from '../../components/Header'

class DetailTemplate extends React.Component {
    constructor(props) {
        super(props)
        const userInfo = _.get(props, "resultMe.detail.result.userInfo", _.get(props, "resultAuth.detail.result.userInfo", null))
        this.state = {
            userInfo: userInfo,
            error: false,
            errorMessage: null,
            isLoading: false,
            id: props.match.params.id
        }
        this.requireMe(userInfo, this.state)
        this.getInitDetail()
    }

    /**
     * Init data display information
     */
     requireMe(userInfo, state) {
        if (_.isNull(userInfo)) {
            this.props.dispatch({ type: ActionTypes.GET_ME_REQUESTED })
            state.isLoading = true
        }
    }

    getInitDetail = () => {
        const detail = { id: this.state.id }
        this.props.dispatch({ type: Types.TEMPLATE_DETAIL.REQUESTED, detail: detail })
    }

    /**
     * Receive props data
     * @param {*} props
     * @param {*} state 
     */
     static getDerivedStateFromProps(props, state) {
        const { resultAuth, resultMe, resultTemplate } = props
        const userInfo = resultMe.detail ? _.get(resultMe, "detail.result.userInfo", null) : _.get(resultAuth, "detail.result.userInfo", null)
        if (!_.isNull(userInfo)) {
            state.isLoading = false
            state.userInfo = userInfo
        }
        const { DetailTemplate } = resultTemplate

        // Get detail record
        var detailTemp = _.get(DetailTemplate, 'detail.result', null)
        if (DetailTemplate.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = DetailTemplate.message
        }
        if (_.has(detailTemp, 'data')) {
            state.detailTemp = detailTemp.data
        }

        return state
    }

    render() {
        const { match } = this.props

        
        return (
            <React.Fragment>
                <Aside match={match} />
                <Header />
                <div className="page-wrapper">
                    <div className="page-body">
                        <div className="container-xl">
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Detail Template</h3>
                                        </div>
                                        <div className="card-body border-bottom py-3">
                                            <div className="d-flex">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    resultMe: state.me,
    resultAuth: state.auth,
    resultTemplate: state.template
})


export default connect(mapStateToProps)(DetailTemplate)