/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { IconHome, IconBrandProducthunt, IconEdit, IconRoute } from '@tabler/icons';
import * as Storage from '../../storage';
// import avatar from '../../assets/img/letterP.svg';
import LogoPoolp from '../Logo/logoPoolp';
import Mobile from '../Header/mobile';

/**
 * Aside conponent
 * Takes in the following props
 * @returns
 */
const Aside = (props) => {
    const userInfo = JSON.parse(localStorage.getItem(Storage.USER_INFO))
    const [comunications, setComunications] = useState([]);
    useEffect(() => {
        if (!_.isUndefined(props.listChat) && !_.isEmpty(props.listChat)) {
            setComunications(props.listChat);
        }
    }, [props.listChat]);
    const showMenu = (match, localPath) => {
        let className = ''
        if (_.includes(match.path, localPath)) {
            className = ' show';
        }
        return className;
    };

    const activeMenu = (match, localPath) => {
        return "nav-link" + (match.url === localPath ? " active" : "");
    };
    return <aside className="navbar navbar-vertical navbar-expand-lg navbar-dark">
        <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
                <span className="navbar-toggler-icon"></span>
            </button>
            <h1 className="navbar-brand navbar-brand-autodark">
                <a href="/"><LogoPoolp /></a>
                <span className='nav-r' onClick={() => window.location.reload()}><IconEdit /></span>
            </h1>
            <Mobile />
            <div className="collapse navbar-collapse" id="navbar-menu">
                <ul className="navbar-nav pt-lg-3">
                    <li className="nav-item">
                        <Link className={"nav-link  " + ((props.match.path === '/prompt' || props.match.path === '/') ? "active" : "")} to="/">
                            <span className="nav-link-icon d-md-none d-lg-inline-block"><IconHome /></span>
                            <span className="nav-link-title">Prompt</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={"nav-link  " + ((props.match.path === '/chat') ? "active" : "")} to="/chat">
                            <span className="nav-link-icon d-md-none d-lg-inline-block"><IconBrandProducthunt /></span>
                            <span className="nav-link-title">ChatPoolp</span>
                        </Link>
                        {Array.isArray(comunications) && _.size(comunications) > 0 && comunications.map((ele, index) =>
                            <div className={"dropdown-menu" + showMenu(props.match, "/chat")} key={index} >
                                <Link className={"dropdown-item " + activeMenu(props.match, '/chat/' + ele.id)} to={'/chat/' + ele.id} onClick={() => window.location.href = '/chat/' + ele.id} >
                                    <span className="nav-link-title"> {ele.content}</span>
                                </Link>
                            </div>
                        )}
                    </li>
                    <li className="nav-item">
                        <Link className={"nav-link  " + ((props.match.path === '/products') ? 'active': '')} to="/products">
                            <span className="nav-link-icon d-md-none d-lg-inline-block"><IconRoute /></span>
                            <span className="nav-link-title">Product</span>
                        </Link>
                        <div className={"dropdown-menu" + showMenu(props.match, "/products")} >
                            <Link className={"dropdown-item " + activeMenu(props.match, '/products/add')} to={'/products/add'} onClick={() => window.location.href = '/products/add'} >
                                <span className="nav-link-title"> Add</span>
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </aside>
};
export default React.memo(Aside);