/* eslint-disable react/prop-types */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import _ from 'lodash';
import { IconX, IconDownload, IconEyeOff, IconEye } from '@tabler/icons';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

const ModalPassword = () => {
    const [eye, setEye] = useState(true);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const handleChange = (event) => {
        
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isChecked = checkValidity();
        if (isChecked) {
            let dataUser = {
                password: password
            }
            dataUser = _.pickBy(dataUser, v => !_.isNull(v) && !_.isUndefined(v));
            const response = await postUser(dataUser);
            if (response) {
                fetchDataUser();
            }
            setShow(false);
        }
    }
    const checkValidity = () => {
        let isValidated = true;
        let tmpErrors = [...errors];
        if (_.isEmpty(password) || _.isUndefined(password) || _.isNull(password)) {
            const err = tmpErrors.filter((item) => item.name === 'password');
            if (!_.size(err)) {
                tmpErrors = tmpErrors.concat([{ name: 'password', message: '' }]);
            }
            isValidated = false;
        }
        if (!password || password.length < PASSWORD_LENGTH.MIN || password.length > PASSWORD_LENGTH.MAX) {
            const err = tmpErrors.filter((item) => item.name === 'password');
            if (!_.size(err)) {
                tmpErrors = tmpErrors.concat([{ name: 'password', message: '' }]);
            }
            isValidated = false;
        }
        setErrors(tmpErrors);
        return isValidated;
    }
    const EyeElement = () => {
        let domShow = <span className="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={() => setEye(false)}><IconEyeOff /></span>;
        if (!eye) {
            domShow = <span className="link-secondary" title="Hidden password" data-bs-toggle="tooltip" onClick={() => setEye(true)}><IconEye /></span>;
        }
        return domShow;
    }
    return (<>
        <span className='link-primary cl-pointer' onClick={handleShow}>
            Change password
        </span>

        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Add a new user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className='mb-3' controlId='password'>
                        <Form.Label>Password<span className='required ms-1'>(*)</span></Form.Label>
                        <InputGroup>
                            <Form.Control
                                className={_.size(errors.filter((item) => item.name === 'password')) ? 'is-invalid' : ''}
                                type={eye ? "password" : "text"}
                                autoFocus
                                autoComplete='new-password'
                                value={password}
                                name='password'
                                onChange={handleChange}
                            />
                            <InputGroup.Text className='cl-pointer'>
                                {EyeElement()}
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='default' type='button' onClick={handleClose}><IconX />Cancel</Button>
                <Button variant='primary ms-auto' type='submit' onClick={handleSubmit}><IconDownload />Save</Button>
            </Modal.Footer>
        </Modal>
    </>);
}

export default ModalPassword;