export const PROFILE_DETAIL = {
    REQUESTED: 'PROFILE_DETAIL_REQUESTED',
    SUCCEEDED: 'PROFILE_DETAIL_SUCCEEDED',
    FAILED: 'PROFILE_DETAIL_FAILED',
    RESET: 'PROFILE_DETAIL_RESET'
};

export const PROFILE_UPDATE = {
    REQUESTED: 'PROFILE_UPDATE_REQUESTED',
    SUCCEEDED: 'PROFILE_UPDATE_SUCCEEDED',
    FAILED: 'PROFILE_UPDATE_FAILED',
    RESET: 'PROFILE_UPDATE_RESET'
};