/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { IconMessage2 } from '@tabler/icons';
import { Modal, Button } from 'react-bootstrap';
import _ from 'lodash';

const ShowProduct = ({data}) => {
    const [isOpen, setIsOpen] = useState(false);
    const content = JSON.parse(data.content);
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const keys = _.keys(content);
    return <>
        <span className='text-primary me-2 cl-pointer' onClick={toggle}><IconMessage2 /></span>
        <Modal show={isOpen} onHide={toggle} className='modal-blur' size='sm' centered>
            <div>
                <button type='button' className='btn-close' onClick={toggle}></button>
                <div className='modal-body py-4'>
                    <h3 className='text-center'>{data.name}</h3>
                    <div className='text-muted'>
                        {Array.isArray(keys) && _.size(keys) > 0 && keys.map((k, i) => <p key={i}>
                            <b>{_.upperFirst(_.lowerCase(k))}</b>: {content[k]}
                        </p>)}
                    </div>
                </div>
                <div className='modal-footer'>
                    <div className='w-100'>
                        <div className='row'>
                            <div className='col'>
                                <Button variant='white w-100' onClick={toggle}>Close</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default ShowProduct;