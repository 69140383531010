import React from 'react';
import { Link } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons';

/**
 * Setup template page Forbidden
 * Set timeout to redirect Dashboard
 */
class GenericNotFound extends React.Component {

    constructor(props) {
        super(props);
        setTimeout(function () {
            window.location.replace("/")
        }, 3000);
    }

    render() {
        return (
            <div className="page page-center">
                <div className="container-tight py-4">
                    <div className="empty">
                        <div className="empty-header">403</div>
                        <p className="empty-title">Oops… You just found an error page</p>
                        <p className="empty-subtitle text-muted">
                            L'accès à cette ressource ne vous ait pas autorisé
                        </p>
                        <div className="empty-action">
                            <Link to={"/"} className="btn btn-primary">
                                <IconArrowLeft/>
                                Retour à l'accueil
                                {/* Take me home */}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GenericNotFound;