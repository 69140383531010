/* eslint-disable react/prop-types */
import React from 'react';
import { Route } from 'react-router-dom';

const FullPageLayout = ({ render, ...rest }) => {
    return (
        <Route
            {...rest}
            render={matchProps => (
                <div className="wrapper">
                    {render(matchProps)}
                </div>
            )}
        />
    );
};

export default FullPageLayout;