import { call, put, takeLatest } from 'redux-saga/effects';

import * as services from './services';
import * as ActionTypes from './actions';

export function* postLogin(action) {
    try {
        var result = yield call(services.postLogin, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.POST_AUTH_FAILED, error: result.message });
        } else {
            yield put({ type: ActionTypes.POST_AUTH_SUCCEEDED, result });
        }
    } catch (e) {
        yield put({ type: ActionTypes.POST_AUTH_FAILED, error: "This action is not valid" });
    }
}

export default function* loginSaga() {
    yield takeLatest(ActionTypes.POST_AUTH_REQUESTED, postLogin);
    
}