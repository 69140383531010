import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { IconChevronLeft, IconChevronRight, IconArrowBarToLeft, IconArrowBarToRight } from '@tabler/icons';

const PREV_PAGE_TEXT = <React.Fragment><IconChevronLeft/> prev</React.Fragment>

class Paging extends Component {
    
    handlePageChange(pageNumber) {
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: "?page=" + pageNumber
        })
        this.props.requestList(pageNumber, false);
    }

    render() {
        const { activePage, itemsCountPerPage, totalItemsCount, pageRangeDisplayed } = this.props;
        const SHOWING_FROM = (activePage - 1) * itemsCountPerPage;
        const SHOWING_TO = (SHOWING_FROM + itemsCountPerPage) > totalItemsCount ? totalItemsCount: (SHOWING_FROM + itemsCountPerPage);

        return (
            <React.Fragment>
                <p className="m-0 text-muted">Showing 
                    <span> {SHOWING_FROM + 1} </span> to 
                    <span> {SHOWING_TO} </span> of 
                    <span> {totalItemsCount} </span> entries</p>
                <Pagination
                    innerClass="pagination m-0 ms-auto"
                    firstPageText={<IconArrowBarToLeft/>}
                    lastPageText={<IconArrowBarToRight/>}
                    prevPageText={PREV_PAGE_TEXT}
                    nextPageText={<IconChevronRight/>}
                    itemClass="page-item"
                    activeClass="active"
                    linkClass="page-link"
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={pageRangeDisplayed}
                    onChange={this.handlePageChange.bind(this)}
                />
            </React.Fragment>
        );
    }
}

export default Paging;
