/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import _ from 'lodash';
import { IconPlus, IconX, IconDownload, IconEyeOff, IconEye } from '@tabler/icons';
import { isEmail } from '../../utils';
import { PASSWORD_LENGTH } from '../../constant';
import { postUser } from './services';

const AddUser = ({ fetchDataUser, userInfo, entities }) => {
    const [eye, setEye] = useState(true);
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [entityId, setEntityId] = useState(userInfo.entityId);
    const [role, setRole] = useState(userInfo.role);
    const [address, setAddress] = useState('');
    const [numberPhone, setNumberPhone] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [status, setStatus] = useState(true);
    const [description, setDescription] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let tmpErrors = [...errors];
        switch (name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'email':
                setEmail(value);
                _.remove(tmpErrors, item => item.name === 'email');
                setErrors(tmpErrors);
                break;
            case 'password':
                setPassword(value);
                _.remove(tmpErrors, item => item.name === 'password');
                setErrors(tmpErrors);
                break;
            case 'entityId':
                setEntityId(value);
                break;
            case 'role':
                setRole(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'numberPhone':
                setNumberPhone(value);
                break;
            case 'zipcode':
                setZipcode(value);
                break;
            case 'status':
                setStatus(e.target.checked);
                break;
            case 'description':
                setDescription(value);
                break;
            default:
                break;
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isChecked = checkValidity();
        if (isChecked) {
            let dataUser = {
                email: email,
                entityId: entityId,
                role: role,
                first_name: firstName,
                last_name: lastName,
                number_phone: numberPhone,
                address: address,
                status: status,
                zipcode: zipcode,
                description: description,
                password: password
            }
            dataUser = _.pickBy(dataUser, v => !_.isNull(v) && !_.isUndefined(v));
            const response = await postUser(dataUser);
            if (response) {
                fetchDataUser();
            }
            setShow(false);
        }
    }
    const checkValidity = () => {
        let isValidated = true;
        let tmpErrors = [...errors];
        if (_.isEmpty(email) || _.isUndefined(email) || _.isNull(email) || !isEmail(email)) {
            const err = tmpErrors.filter((item) => item.name === 'email');
            if (!_.size(err)) {
                tmpErrors = tmpErrors.concat([{ name: 'email', message: '' }]);
            }
            isValidated = false;
        }
        if (_.isEmpty(password) || _.isUndefined(password) || _.isNull(password)) {
            const err = tmpErrors.filter((item) => item.name === 'password');
            if (!_.size(err)) {
                tmpErrors = tmpErrors.concat([{ name: 'password', message: '' }]);
            }
            isValidated = false;
        }
        if (!password || password.length < PASSWORD_LENGTH.MIN || password.length > PASSWORD_LENGTH.MAX) {
            const err = tmpErrors.filter((item) => item.name === 'password');
            if (!_.size(err)) {
                tmpErrors = tmpErrors.concat([{ name: 'password', message: '' }]);
            }
            isValidated = false;
        }
        setErrors(tmpErrors);
        return isValidated;
    }
    const EyeElement = () => {
        let domShow = <span className="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={() => setEye(false)}><IconEyeOff/></span>;
        if (!eye) {
            domShow = <span className="link-secondary" title="Hidden password" data-bs-toggle="tooltip" onClick={() => setEye(true)}><IconEye/></span>;
        }
        return domShow;
    }
    return (
        <>
            <Button variant='primary' onClick={handleShow}>
                <IconPlus /> Add User
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add a new user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className='mb-3' controlId='email'>
                            <Form.Label>Email address<span className='required ms-1'>(*)</span></Form.Label>
                            <Form.Control
                                className={_.size(errors.filter((item) => item.name === 'email')) ? 'is-invalid': ''}
                                type='email'
                                autoComplete='email'
                                placeholder='name@example.com'
                                autoFocus
                                value={email}
                                name='email'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='password'>
                            <Form.Label>Password<span className='required ms-1'>(*)</span></Form.Label>
                            <InputGroup>
                                <Form.Control
                                    className={_.size(errors.filter((item) => item.name === 'password')) ? 'is-invalid' : ''}
                                    type={eye ? "password" : "text"}
                                    autoFocus
                                    autoComplete='new-password'
                                    value={password}
                                    name='password'
                                    onChange={handleChange}
                                />
                                <InputGroup.Text className='cl-pointer'>
                                    {EyeElement()}
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='entityId'>
                            <Form.Label>Entity<span className='required ms-1'>(*)</span></Form.Label>
                            <Form.Select name='entityId' onChange={handleChange} defaultValue={entityId}>
                                {Array.isArray(entities) && entities.map((e, k) => 
                                    <option key={k} value={e.id}>{e.name}</option>
                                )}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='firstName'>
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                value={firstName}
                                name='firstName'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='lastName'>
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                value={lastName}
                                name='lastName'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='role'>
                            <Form.Label>Role</Form.Label>
                            <Form.Select name='role' onChange={handleChange} defaultValue={1}>
                                {userInfo.role >= 1 && <option value={1}>User</option>}
                                {userInfo.role >= 2 && <option value={2}>Assistant</option>}
                                {userInfo.role >= 3 && <option value={3}>Admin</option>}
                                {userInfo.role === 4 && <option value={4}>Super Admin</option>}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='numberPhone'>
                            <Form.Label>Phone number</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                value={numberPhone}
                                name='numberPhone'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='zipcode'>
                            <Form.Label>ZIP Code</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                value={zipcode}
                                name='zipcode'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Switch
                            className='mb-3'
                            id='status'
                            type="switch"
                            autoFocus
                            checked={status}
                            name='status'
                            onChange={handleChange}
                            label='Status'
                        />
                        <Form.Group className='mb-3' controlId='description'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as='textarea'
                                rows={3}
                                value={description}
                                name='description'
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='default' type='button' onClick={handleClose}><IconX />Cancel</Button>
                    <Button variant='primary ms-auto' type='submit' onClick={handleSubmit}><IconDownload />Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddUser;