import { call, put, takeLatest } from 'redux-saga/effects';

import * as services from './services';
import * as ActionTypes from './actions';

export function* getListTemplate(action) {
    try {
        var result = yield call(services.getListTemplate, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.TEMPLATE_LIST.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.TEMPLATE_LIST.SUCCEEDED, result });
        }
    } catch (e) {
        yield put({ type: ActionTypes.TEMPLATE_LIST.FAILED, error: "This action is not valid!" });
    }
}

export function* getTemplate(action) {
    try {
        var result = yield call(services.getTemplate, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.TEMPLATE_DETAIL.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.TEMPLATE_DETAIL.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.TEMPLATE_DETAIL.FAILED, error: "This action is not valid!" });
    }
}

export function* postTemplate(action) {
    try {
        const result = yield call( services.postTemplate, action.detail)
        if (result.error) {
            yield put({ type: ActionTypes.TEMPLATE_CREATE.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.TEMPLATE_CREATE.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.TEMPLATE_CREATE.FAILED, error: "This action is not valid!" });
    }
}

export function* putTemplate(action) {
    try {
        const result = yield call( services.putTemplate, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.TEMPLATE_UPDATE.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.TEMPLATE_UPDATE.SUCCEEDED, data: result.data })
        }
    } catch (e) {
        console.log('catch')
        yield put({ type: ActionTypes.TEMPLATE_UPDATE.FAILED, error: "This action is not valid!" });
    }
}

export function* deleteTemplate(action) {
    try {
        const result = yield call( services.deleteTemplate, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.TEMPLATE_REMOVE.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.TEMPLATE_REMOVE.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.TEMPLATE_REMOVE.FAILED, error: "This action is not valid!" });
    }
}

export function* importTemplate(action) {
    try {
        const result = yield call( services.importTemplate, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.TEMPLATE_IMPORT.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.TEMPLATE_IMPORT.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.TEMPLATE_IMPORT.FAILED, error: "This action is not valid!" });
    }
}

export function* countCategory(action) {
    try {
        const result = yield call( services.countCategory, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.TEMPLATE_COUNT.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.TEMPLATE_COUNT.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.TEMPLATE_COUNT.FAILED, error: "This action is not valid!" });
    }
}

export default function* templateSaga() {
    yield takeLatest(ActionTypes.TEMPLATE_LIST.REQUESTED, getListTemplate)
    yield takeLatest(ActionTypes.TEMPLATE_DETAIL.REQUESTED, getTemplate)
    yield takeLatest(ActionTypes.TEMPLATE_CREATE.REQUESTED, postTemplate)
    yield takeLatest(ActionTypes.TEMPLATE_UPDATE.REQUESTED, putTemplate)
    yield takeLatest(ActionTypes.TEMPLATE_REMOVE.REQUESTED, deleteTemplate)
    yield takeLatest(ActionTypes.TEMPLATE_IMPORT.REQUESTED, importTemplate)
    yield takeLatest(ActionTypes.TEMPLATE_COUNT.REQUESTED, countCategory)
}