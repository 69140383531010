/* eslint-disable react/prop-types */
import React from 'react'
import Button from 'react-bootstrap/Button'
import ClipLoader from 'react-spinners/ClipLoader'

const ButtonClipLoaders = (props) => {
    const { 
        style={}, 
        isLoading = false, 
        icon='', 
        size=20, 
        disabled=false, 
        className='', 
        label, 
        onClick=()=>{}, 
        variant='danger w-100', 
        color='#1e293b', 
        type='submit', 
        tooltiptext='' 
    } = props;

    const RenderDiv = () => {
        if (tooltiptext !== '') {
            return <Button style={style} disabled={isLoading || disabled} className={className + ' button-clip-loader tooltip1'} variant={variant} type={type} onClick={onClick}>
                {isLoading ? <ClipLoader size={size} color={color} /> : icon} {label ? <>{label}</> : ''}
                <span className='tooltiptext'>{tooltiptext}</span>
            </Button>   
        } else {
            return <Button style={style} disabled={isLoading || disabled} className={className + ' button-clip-loader'} variant={variant} type={type} onClick={onClick}>
                {isLoading ? <ClipLoader size={size} color={color} /> : icon} {label ? <>{label}</> : ''}
            </Button>   
        }
    }
    return <RenderDiv/>
}

export default ButtonClipLoaders;
