import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import * as ActionTypes from '../../actions/types'
import * as Types from './actions'
import Aside from '../../components/Aside'
import Header from '../../components/Header'

class DetailCategory extends React.Component {
    constructor(props) {
        super(props)
        const userInfo = _.get(props, "resultMe.detail.result.userInfo", _.get(props, "resultAuth.detail.result.userInfo", null))
        this.state = {
            userInfo: userInfo,
            error: false,
            errorMessage: null,
            isLoading: false,
            id: props.match.params.id
        }
        this.requireMe(userInfo, this.state)
        this.getInitDetail()
    }

    /**
     * Init data display information
     */
    requireMe(userInfo, state) {
        if (_.isNull(userInfo)) {
            this.props.dispatch({ type: ActionTypes.GET_ME_REQUESTED })
            state.isLoading = true
        }
    }

    getInitDetail = () => {
        const detail = { id: this.state.id }
        this.props.dispatch({ type: Types.CATEGORY_DETAIL.REQUESTED, detail: detail })
    }

    /**
     * Receive props data
     * @param {*} props
     * @param {*} state 
     */
    static getDerivedStateFromProps(props, state) {
        const { resultAuth, resultMe, resultCategory } = props
        const userInfo = resultMe.detail ? _.get(resultMe, "detail.result.userInfo", null) : _.get(resultAuth, "detail.result.userInfo", null)
        if (!_.isNull(userInfo)) {
            state.isLoading = false
            state.userInfo = userInfo
        }
        const { DetailCategory } = resultCategory

        // Get detail record
        var detailCate = _.get(DetailCategory, 'detail', null)
        if (DetailCategory.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = DetailCategory.message
        }
        if (_.has(detailCate, 'data')) {
            state.detailCate = detailCate.data
        }

        return state
    }

    render() {
        const { match } = this.props
        const { detailCate } = this.state
        
        return (
            <React.Fragment>
                <Aside match={match} />
                <Header />
                <div className="page-wrapper">
                    <div className="page-body">
                        <div className="container-xl">
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Detail Category</h3>
                                        </div>
                                        <div className="card-body border-bottom py-3">
                                            <div className="row">
                                                {detailCate && 
                                                <div className="col-12 my-5">
                                                    <h2>{detailCate.name}</h2>
                                                    <p>Code: <span>{detailCate.code}</span></p>
                                                    <p>Created: <span>{detailCate.createdAt}</span></p>
                                                    <p>Updated: <span>{detailCate.updatedAt}</span></p>
                                                </div>}
                                            </div>
                                            <table className="table table-transparent table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" style={{width: '1%'}}></th>
                                                        <th>Product</th>
                                                        <th className="text-center" style={{width: '1%'}}>Qnt</th>
                                                        <th className="text-end" style={{width: '1%'}}>Unit</th>
                                                        <th className="text-end" style={{width: '1%'}}>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center">1</td>
                                                        <td>
                                                            <p className="strong mb-1">Logo Creation</p>
                                                            <div className="text-muted">Logo and business cards design</div>
                                                        </td>
                                                        <td className="text-center">
                                                            1
                                                        </td>
                                                        <td className="text-end">$1.800,00</td>
                                                        <td className="text-end">$1.800,00</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">2</td>
                                                        <td>
                                                            <p className="strong mb-1">Online Store Design &amp; Development</p>
                                                            <div className="text-muted">Design/Development for all popular modern browsers</div>
                                                        </td>
                                                        <td className="text-center">
                                                            1
                                                        </td>
                                                        <td className="text-end">$20.000,00</td>
                                                        <td className="text-end">$20.000,00</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">3</td>
                                                        <td>
                                                            <p className="strong mb-1">App Design</p>
                                                            <div className="text-muted">Promotional mobile application</div>
                                                        </td>
                                                        <td className="text-center">
                                                            1
                                                        </td>
                                                        <td className="text-end">$3.200,00</td>
                                                        <td className="text-end">$3.200,00</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4" className="strong text-end">Subtotal</td>
                                                        <td className="text-end">$25.000,00</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4" className="strong text-end">Vat Rate</td>
                                                        <td className="text-end">20%</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4" className="strong text-end">Vat Due</td>
                                                        <td className="text-end">$5.000,00</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4" className="font-weight-bold text-uppercase text-end">Total Due</td>
                                                        <td className="font-weight-bold text-end">$30.000,00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p className="text-muted text-center mt-5">Thank you very much for doing business with us. We look forward to working with
                                                you again!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    resultMe: state.me,
    resultAuth: state.auth,
    resultCategory: state.category
})

export default connect(mapStateToProps)(DetailCategory)