export const CATEGORY_LIST = {
    REQUESTED: "CATEGORY_LIST_REQUESTED",
    SUCCEEDED: "CATEGORY_LIST_SUCCEEDED",
    FAILED: "CATEGORY_LIST_FAILED",
    RESET: "CATEGORY_LIST_RESET",
}

export const CATEGORY_CREATE = {
    REQUESTED: "CATEGORY_CREATE_REQUESTED",
    SUCCEEDED: "CATEGORY_CREATE_SUCCEEDED",
    FAILED: "CATEGORY_CREATE_FAILED",
    RESET: "CATEGORY_CREATE_RESET",
}

export const CATEGORY_UPDATE = {
    REQUESTED: "CATEGORY_UPDATE_REQUESTED",
    SUCCEEDED: "CATEGORY_UPDATE_SUCCEEDED",
    FAILED: "CATEGORY_UPDATE_FAILED",
    RESET: "CATEGORY_UPDATE_RESET",
}

export const CATEGORY_DETAIL = {
    REQUESTED: "CATEGORY_DETAIL_REQUESTED",
    SUCCEEDED: "CATEGORY_DETAIL_SUCCEEDED",
    FAILED: "CATEGORY_DETAIL_FAILED",
    RESET: "CATEGORY_DETAIL_RESET",
}

export const CATEGORY_REMOVE = {
    REQUESTED: "CATEGORY_REMOVE_REQUESTED",
    SUCCEEDED: "CATEGORY_REMOVE_SUCCEEDED",
    FAILED: "CATEGORY_REMOVE_FAILED",
    RESET: "CATEGORY_REMOVE_RESET",
}