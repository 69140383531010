import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import _ from 'lodash'
import { countCategory } from './services';

class PopoverCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listCat: [],
        }
    }

    /**
     * Handle count category relation template
     * @param {*} id 
     */
    handleCategory = async (id) => {
        const detail = { id: id }
        const listCat = await countCategory(detail)
        this.setState({ listCat: listCat.data })
    }

    render() {
        const { tempId, countCategory } = this.props
        const { listCat } = this.state

        const popover = <Popover title="relation categories">
            <Popover.Body>
                <div className="row">
                    {Array.isArray(listCat) && listCat.length > 0 && listCat.map((row, i) =>
                        <Link key={i} to={"/categories/" + row.category_id} className="dropdown-item"><b>{row.category_id + ". " + row.category_name}</b></Link>)}
                </div>
            </Popover.Body>
        </Popover>

        return (
            <OverlayTrigger
                trigger="click"
                rootClose
                overlay={popover}>
                <span className="badge badge-pill bg-green ms-2 cl-pointer count-bg" onClick={() => this.handleCategory(tempId)}>{countCategory}</span>
            </OverlayTrigger>
        )
    }
}

export default PopoverCategory