import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Modal, Form, Button } from 'react-bootstrap'
import { IconSquarePlus, IconDownload, IconX } from '@tabler/icons'

import * as Types from './actions'
import { CATEGORY_LIST } from '../Categories/actions'
class ModalAdd extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            listCat: [],
            categoryCheck: []
        }
    }

    toggle = () => {
        if (this.state.isOpen === false) {
            const QUERY_LIST = {
                sortBy: 'createdAt',
                order: 'DESC',
                limit: 1000,
                offset: 0
            }
            this.props.dispatch({ type: CATEGORY_LIST.REQUESTED, detail: QUERY_LIST })
        }
        this.setState({
            isOpen: !this.state.isOpen,
            categoryCheck: []
        })
    }

    handleInputChange = (event) => {
        const target = event.target;
        if (target.checked) {
            this.state.categoryCheck.push(target.value)
        } else {
            var array = [...this.state.categoryCheck]; // make a separate copy of the array
            const index = array.indexOf(target.value)
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({categoryCheck: array});
            }
        }
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const name = event.target.getElementsByTagName('input')['name'].value
        const comment = event.target.getElementsByTagName('input')['comment'].value
        const content = event.target.getElementsByTagName('textarea')['content'].value
        const categoryCheck = this.state.categoryCheck
        const template = {
            name, comment, content, categoryCheck
        }
        this.props.dispatch({ type: Types.TEMPLATE_CREATE.REQUESTED, detail: template })
        this.toggle()
    }

    static getDerivedStateFromProps(props, state) {
        const { resultCategory } = props
        const { ListCategory } = resultCategory

        // Get list record
        var dataCate = _.get(ListCategory, 'detail.result', null)
        if (ListCategory.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = ListCategory.message
        }
        if (_.has(dataCate, 'data')) {
            // ===== begin lazyloading =====
            // var arrayList = [...state.listCat]
            // const updateCat = dataCate.data
            // if (updateCat.length > 0) {
            //     state.listCat = _.concat(arrayList, updateCat)
            // }
            // ===== stop lazyloading =====
            state.listCat = dataCate.data
        }
        return state
    }

    render() {
        const { listCat } = this.state
        
        return (
            <React.Fragment>
                <Button className="btn btn-primary" onClick={this.toggle}><IconSquarePlus/>Add</Button>
                <Modal show={this.state.isOpen} onHide={this.toggle} className="modal-blur" centered size="lg">
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>New template</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label required">Name</label>
                                <input id="name" type="text" className="form-control" name="name" placeholder="Name"/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="comment" className="form-label">Comment</label>
                                <input id="comment" type="text" className="form-control" name="comment" placeholder="Comment"/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="content" className="form-label required">Text</label>
                                <textarea id="content" type="text" className="form-control" name="content" rows="6" placeholder="Content"></textarea>
                            </div>
                            <div className="mb-3">
                                <div className="form-label">Checkboxes</div>
                                <div>
                                    {Array.isArray(listCat) && listCat.map((row, i) => 
                                    <label key={i} className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name={"category_" + row.id} value={row.id} onChange={this.handleInputChange}/>
                                        <span className="form-check-label">{row.name}</span>
                                    </label>)}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link-secondary" onClick={this.toggle}><IconX/>Cancel</Button>
                            <Button variant="primary ms-auto" type="submit"><IconDownload />Save</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    resultCategory: state.category
})

export default connect(mapStateToProps)(ModalAdd)