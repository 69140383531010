import fetch from 'isomorphic-fetch';
import * as Utils from '../../utils';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;

export const postPrompt = async (data) => {
    const response = await fetch(`${BASE_URL}/ai-prompt`, {
        method: 'POST',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    });
    return Utils.handleNon200s(response);
}