import * as ActionTypes from '../actions';

const defaultState = {
    message: null,
    inProgress: false,
    done: false,
    error: false
};

const ExportCsv = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.CSV_EXPORT.REQUESTED:
            return {
                ...state,
                inProgress: true,
                done: false,
                error: false,
                message: 'Loading',
            };
        case ActionTypes.CSV_EXPORT.SUCCEEDED:
            return {
                ...state,
                detail: action,
                inProgress: false,
                done: true,
                error: false,
                message: 'Success'
            }
        case ActionTypes.CSV_EXPORT.FAILED:
            return {
                ...state,
                inProgress: false,
                done: true,
                error: true,
                message: action.error
            }
        case ActionTypes.CSV_EXPORT.RESET:
            return defaultState;
        default:
            return state;
    }
};


export default ExportCsv;