import { call, put, takeLatest } from 'redux-saga/effects';

import * as services from '../services/atlas';
import * as ActionTypes from '../actions/types';

/**
 * generator services.getMe
 */
export function* getMe() {
    try {
        var result = yield call(services.getMe);
        if (result.error) {
            yield put({ type: ActionTypes.GET_ME_FAILED, error: result.message });
        } else {
            yield put({ type: ActionTypes.GET_ME_SUCCEEDED, result });
        }
    } catch (e) {
        yield put({ type: ActionTypes.GET_ME_FAILED, error: "This action is not valid !" });
    }
}

export default function* atlasSaga() {
    yield takeLatest(ActionTypes.GET_ME_REQUESTED, getMe);
}