/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import "react-chat-elements/dist/main.css";
import { MessageList } from "react-chat-elements";
import { Input } from 'react-chat-elements';
import { Button } from "react-chat-elements";
import { IconSend } from '@tabler/icons';
import _ from 'lodash';
import { Form } from 'react-bootstrap';

import avatar from '../../assets/img/letterP.svg';
import smile from '../../assets/img/smile.svg';
// import chatTyping from '../../assets/img/chat-typing.svg';

import Aside from '../../components/Aside';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import * as Storage from '../../storage';
import * as Types from './actions';
import { postChat } from './services';

import { ObjectToQueryString } from "../../utils";
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;
var eventSource = null;
/**
 * ChatPoolpDetail page
 * constructor: data userinfo
 */
class ChatPoolpDetail extends React.Component {
    constructor(props) {
        super(props)
        const userInfo = JSON.parse(localStorage.getItem(Storage.USER_INFO))
        const chatId = parseInt(_.get(props.match.params, 'id', 0));
        this.state = {
            chatId: chatId,
            uuid: null,
            userInfo: userInfo,
            error: false,
            errorMessage: null,
            isLoading: false,
            prompt: '',
            responsePrompt: '',
            dataSource: [],
            buttonDisabled: '',
            listChat: []
        }
        props.dispatch({ type: Types.CHAT_LIST.REQUESTED })
        if (chatId) {
            const detail = { chatId: chatId }
            props.dispatch({ type: Types.CHAT_DETAIL.REQUESTED, detail })
        }
    }
    /**
     * Receive props data
     * @param {*} props
     * @param {*} state 
     */
    static getDerivedStateFromProps(props, state) {
        const { resultChat } = props;
        const { ListChat, DetailChat } = resultChat
        const listC = _.get(ListChat, 'detail.result', null);
        const detailC = _.get(DetailChat, 'detail.result', null);
        if (_.has(listC, 'data')) {
            state.listC = listC.data;
        }
        if (_.has(detailC, 'data')) {
            state.detailC = detailC.data;
            const communicationMessage = detailC.data.communicationMessage;
            var dataFlex = [];
            communicationMessage.map((ele) => {
                if (ele.role === 'user') {
                    dataFlex.push({
                        position: 'right',
                        type: 'text',
                        title: state.userInfo.first_name + ' ' + state.userInfo.last_name,
                        avatar: smile,
                        avatarFlexible: true,
                        date: new Date(ele.updatedAt),
                        text: ele.message
                    });
                }
                if (ele.role !== 'user') {
                    dataFlex.push({
                        position: 'left',
                        type: 'text',
                        title: 'Poolpai',
                        avatar: avatar,
                        avatarFlexible: true,
                        date: new Date(ele.updatedAt),
                        text: ele.message
                    });
                }
                state.dataSource = dataFlex;
            });
        }
        return state;
    }
    handleChangePrompt = (event) => {
        this.setState({
            prompt: event.target.value
        });
    }
    handleSubmitPrompt = async (event) => {
        event.preventDefault();
        const { dataSource, prompt, responsePrompt, chatId, uuid } = this.state;
        this.setState({
            prompt: '',
            buttonDisabled: 'disabled'
        });
        var [...dataFlex] = dataSource
        const userPrompt = {
            position: 'right',
            type: 'text',
            title: this.state.userInfo.first_name + ' ' + this.state.userInfo.last_name,
            avatar: smile,
            avatarFlexible: true,
            date: new Date(),
            text: prompt
        }
        const poolpaiPrompt = {
            position: 'left',
            type: 'text',
            title: 'Poolpai',
            avatar: avatar,
            avatarFlexible: true,
            date: new Date(),
            text: responsePrompt
        }
        const dataInit = [userPrompt, poolpaiPrompt]
        if (_.isEmpty(dataSource)) {
            dataFlex = dataInit;
        } else {
            dataFlex.push(userPrompt, poolpaiPrompt);
        }
        this.eventConferences(dataFlex)
        const dataRequest = {
            id: chatId, uuid: uuid, message: prompt
        }
        await postChat(dataRequest);
    }

    eventConferences = (dataFlex) => {
        let jwtToken = JSON.parse(localStorage.getItem(Storage.JWT_TOKEN))
        const params = { token: jwtToken }
        if (eventSource) {
            eventSource.close();
        };
        this.setState({ dataSource: dataFlex });
        eventSource = new EventSource(`${BASE_URL}/ai-chat?${ObjectToQueryString(params)}`)
        eventSource.addEventListener('message', (event) => {
            const eventData = JSON.parse(event.data)
            const indexPoolp = _.findLastIndex(dataFlex, { title: 'Poolpai' });
            if (indexPoolp !== -1) {
                dataFlex[indexPoolp].text = dataFlex[indexPoolp].text + eventData.message.content;
                this.setState({ dataSource: dataFlex });
            }
            if (eventData.done) {
                this.setState({ buttonDisabled: '' });
                eventSource.close();
            }
        })
    }

    componentDidUpdate() {
        const { DetailChat } = this.props.resultChat;
        if (_.get(DetailChat, "detail.result.data", false)) {
            this.props.dispatch({ type: Types.CHAT_DETAIL.RESET });
        }
    }
    /* --------------------------------------------
            RENDERING
    -------------------------------------------- */
    render() {
        const { match } = this.props
        const { userInfo, listC } = this.state
        return (
            <React.Fragment>
                <Aside match={match} listChat={listC} />
                <Header userInfo={userInfo} />
                <div className="page-wrapper mt-55">
                    <div className="page-body">
                        <div className="container-xl">
                            <div className="row row-deck row-cards prompt-content">
                                <MessageList
                                    className='message-list'
                                    lockable={true}
                                    toBottomHeight={'100%'}
                                    dataSource={this.state.dataSource}
                                />
                            </div>
                            <div className="row-cards input-ms mt-5">
                                <Form onSubmit={this.handleSubmitPrompt} className='form-request' >
                                    <Input
                                        placeholder="Type here..."
                                        multiline={false}
                                        value={this.state.prompt}
                                        onChange={this.handleChangePrompt}
                                        rightButtons={<Button
                                            text={''}
                                            onClick={() => this.handleSubmitPrompt}
                                            title='Send'
                                            type='Submit'
                                            icon={{
                                                float: 'left',
                                                size: 15,
                                                component: <IconSend />
                                            }}
                                            disabled={this.state.buttonDisabled}
                                        />}
                                    />
                                </Form>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    resultChat: state.chat
});

export default connect(mapStateToProps)(ChatPoolpDetail);