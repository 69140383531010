import React, { Component } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { IconEditCircle, IconDownload, IconCircleX } from '@tabler/icons'

import * as Types from './actions'

class ModalEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
            name: this.props.category.name,
            code: this.props.category.code
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const name = event.target.getElementsByTagName('input')['name'].value;
        const category = {
            name, id: this.props.category.id
        };
        this.props.dispatch({ type: Types.CATEGORY_UPDATE.REQUESTED, detail: category })
        this.toggle();
    }

    render() {
        const { name, code } = this.state
        return (
            <React.Fragment>
                <span onClick={this.toggle} className="text-primary me-2 cl-pointer"><IconEditCircle /></span>
                <Modal show={this.state.isOpen} onHide={this.toggle} className="modal-blur" centered size="lg" >
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update category</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-3 row">
                                <div className="col-lg-6">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input id="name" type="text" className="form-control" name="name" placeholder="Name category" defaultValue={name} />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="name" className="form-label">Code</label>
                                    <input id="code" type="text" className="form-control" name="code" placeholder="Code category" value={code} readOnly />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link-secondary" onClick={this.toggle}><IconCircleX/>Cancel</Button>
                            <Button variant="primary ms-auto" type="submit"><IconDownload/>Save</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalEdit;

