import { call, put, takeLatest } from 'redux-saga/effects';

import * as services from './services';
import * as ActionTypes from './actions';

export function* getListAttribute(action) {
    try {
        var result = yield call(services.getListAttribute, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.ATTRIBUTE_LIST.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.ATTRIBUTE_LIST.SUCCEEDED, result });
        }
    } catch (e) {
        yield put({ type: ActionTypes.ATTRIBUTE_LIST.FAILED, error: "This action is not valid!" });
    }
}

export function* postAttribute(action) {
    try {
        var result = yield call(services.postAttribute, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.ATTRIBUTE_CREATE.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.ATTRIBUTE_CREATE.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.ATTRIBUTE_CREATE.FAILED, error: "This action is not valid" });
    }
}

export function* putAttribute(action) {
    try {
        var result = yield call(services.putAttribute, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.ATTRIBUTE_UPDATE.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.ATTRIBUTE_UPDATE.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.ATTRIBUTE_UPDATE.FAILED, error: "This action is not valid" });
    }
}

export function* getAttribute(action) {
    try {
        var result = yield call(services.getAttribute, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.ATTRIBUTE_DETAIL.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.ATTRIBUTE_DETAIL.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.ATTRIBUTE_DETAIL.FAILED, error: "This action is not valid!" });
    }
}

export function* deleteAttribute(action) {
    try {
        var result = yield call(services.deleteAttribute, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.ATTRIBUTE_REMOVE.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.ATTRIBUTE_REMOVE.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.ATTRIBUTE_REMOVE.FAILED, error: "This action is not valid!" });
    }
}


export default function* attributeSaga() {
    yield takeLatest(ActionTypes.ATTRIBUTE_LIST.REQUESTED, getListAttribute);
    yield takeLatest(ActionTypes.ATTRIBUTE_CREATE.REQUESTED, postAttribute);
    yield takeLatest(ActionTypes.ATTRIBUTE_UPDATE.REQUESTED, putAttribute);
    yield takeLatest(ActionTypes.ATTRIBUTE_DETAIL.REQUESTED, getAttribute);
    yield takeLatest(ActionTypes.ATTRIBUTE_REMOVE.REQUESTED, deleteAttribute);

}