import { call, put, takeLatest } from 'redux-saga/effects';

import * as services from './services';
import * as ActionTypes from './actions';

export function* getListCategory(action) {
    try {
        var result = yield call(services.getListCategory, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.CATEGORY_LIST.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.CATEGORY_LIST.SUCCEEDED, result });
        }
    } catch (e) {
        yield put({ type: ActionTypes.CATEGORY_LIST.FAILED, error: "This action is not valid!" });
    }
}

export function* postCategory(action) {
    try {
        var result = yield call(services.postCategory, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.CATEGORY_CREATE.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.CATEGORY_CREATE.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.CATEGORY_CREATE.FAILED, error: "This action is not valid" });
    }
}

export function* putCategory(action) {
    try {
        var result = yield call(services.putCategory, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.CATEGORY_UPDATE.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.CATEGORY_UPDATE.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.CATEGORY_UPDATE.FAILED, error: "This action is not valid" });
    }
}

export function* getCategory(action) {
    try {
        var result = yield call(services.getCategory, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.CATEGORY_DETAIL.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.CATEGORY_DETAIL.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.CATEGORY_DETAIL.FAILED, error: "This action is not valid!" });
    }
}

export function* deleteCategory(action) {
    try {
        var result = yield call(services.deleteCategory, action.detail);
        if (result.error) {
            yield put({ type: ActionTypes.CATEGORY_REMOVE.FAILED, error: result.errMessage });
        } else {
            yield put({ type: ActionTypes.CATEGORY_REMOVE.SUCCEEDED, data: result.data });
        }
    } catch (e) {
        yield put({ type: ActionTypes.CATEGORY_REMOVE.FAILED, error: "This action is not valid!" });
    }
}


export default function* categorySaga() {
    yield takeLatest(ActionTypes.CATEGORY_LIST.REQUESTED, getListCategory);
    yield takeLatest(ActionTypes.CATEGORY_CREATE.REQUESTED, postCategory);
    yield takeLatest(ActionTypes.CATEGORY_UPDATE.REQUESTED, putCategory);
    yield takeLatest(ActionTypes.CATEGORY_DETAIL.REQUESTED, getCategory);
    yield takeLatest(ActionTypes.CATEGORY_REMOVE.REQUESTED, deleteCategory);

}