import * as ActionTypes from "./../actions"

const defaultState = {
    message: null,
    inProgress: false,
    done: false,
    error: false
}

const ProfileUpdate = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.PROFILE_UPDATE.REQUESTED:
            return {
                ...state,
                inProgress: true,
                done: false,
                error: false,
                message: 'Loading',
            }
        case ActionTypes.PROFILE_UPDATE.SUCCEEDED:
            return {
                ...state,
                detail: action,
                inProgress: false,
                done: true,
                error: false,
                message: 'Successfully'
            }
        case ActionTypes.PROFILE_UPDATE.FAILED:
            return {
                ...state,
                inProgress: false,
                done: true,
                error: true,
                message: action.error
            }
        case ActionTypes.PROFILE_UPDATE.RESET:
            return defaultState;
        default:
            return state;
    }
}

export default ProfileUpdate;