/* eslint-disable react/prop-types */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import _ from 'lodash';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { IconSquarePlus, IconCircleMinus } from '@tabler/icons';

const AddAttribute = ({ prodId, product, updateProducts }) => {
    const [attributes, setAttributes] = useState([...product.attributes])
    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        setAttributes([...product.attributes]);
    }, [product]);
    useLayoutEffect(() => {
        if (_.size(attributes) > 1) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    });
    const addAttribute = () => {
        const dataUpdate = [...attributes, {
            label: '',
            value: '',
            err_label: false,
            err_value: false
        }];
        setAttributes(dataUpdate)
        updateProducts(prodId, dataUpdate);
    }
    const deleteAttribute = (e, idx) => {
        if (_.size(attributes) > 1) {
            const dataUpdate = attributes.filter(function (item, key) {
                return key !== idx;
            })
            setAttributes(dataUpdate);
            updateProducts(prodId, dataUpdate);
        }
    }
    const handleChange = (event) => {
        let data_name = event.target.name.split('-');
        if (data_name[2]) {
            const attrId = data_name[2];
            const dataAttribute = [...attributes];
            if (['label', 'value'].includes(data_name[0])) {
                if (event.target.value.trim() === '') {
                    dataAttribute[attrId]['err_' + data_name[0]] = true;
                } else {
                    dataAttribute[attrId]['err_' + data_name[0]] = false;
                }
                dataAttribute[attrId][data_name[0]] = event.target.value;
                setAttributes(dataAttribute);
                updateProducts(prodId, dataAttribute);
            }
        }
    }
    return (<>
        <div className='card-header'>
            <h3>Attributes <span><IconSquarePlus className='link-primary cl-pointer' size={24} onClick={addAttribute} /></span></h3>
        </div>
        <div className='card-body'>
            {Array.isArray(attributes) && _.size(attributes) > 0 && attributes.map((val, idx) => {
                let labelId = `label-${prodId}-${idx}`;
                let valueId = `value-${prodId}-${idx}`;
                return <div key={idx} className='form-attributes mr-top-30'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id={labelId}>Label & Value<span className='required ms-1'>(*)</span></InputGroup.Text>
                        <Form.Control id={labelId} name={labelId} aria-describedby={labelId} className={`form-control round ${attributes[idx].err_label && 'is-invalid'}`} value={attributes[idx].label} onChange={(e) => handleChange(e)} />
                        <Form.Control id={valueId} name={valueId} aria-describedby={valueId} className={`form-control round ${attributes[idx].err_value && 'is-invalid'}`} value={attributes[idx].value} onChange={(e) => handleChange(e)} />
                        <InputGroup.Text className='cl-pointer col-auto' disabled={disabled} onClick={(e) => deleteAttribute(e, idx)}><IconCircleMinus /></InputGroup.Text>
                    </InputGroup>
                </div>
            })}
        </div>
    </>);
}

export default AddAttribute;