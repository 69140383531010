/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import _ from 'lodash';
import { IconEditCircle, IconX, IconDownload } from '@tabler/icons';
import { putEntity } from './services';
const UpdateEntity = ({ entity, fetchDataEntity }) => {
    const data = _.pickBy(entity, v => !_.isNull(v) && !_.isUndefined(v));
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState(_.get(data, 'email', ''));
    const [name, setName] = useState(_.get(data, 'name', ''));
    const [website, setWebsite] = useState(_.get(data, 'website', ''));
    const [address, setAddress] = useState(_.get(data, 'address', ''));
    const [numberPhone, setNumberPhone] = useState(_.get(data, 'number_phone', ''));
    const [zipcode, setZipcode] = useState(_.get(data, 'zipcode', ''));
    const [status, setStatus] = useState(_.get(data, 'status', true));
    const [description, setDescription] = useState(_.get(data, 'description', ''));
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case 'email':
                setEmail(value);
                break;
            case 'name':
                setName(value);
                break;
            case 'website':
                setWebsite(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'numberPhone':
                setNumberPhone(value);
                break;
            case 'zipcode':
                setZipcode(value);
                break;
            case 'status':
                setStatus(e.target.checked);
                break;
            case 'description':
                setDescription(value);
                break;
            default:
                break;
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isChecked = checkValidity();
        if (isChecked) {
            let dataEntity = {
                id: data.id,
                email: email,
                name: name,
                website: website,
                number_phone: numberPhone,
                address: address,
                status: status,
                zipcode: zipcode,
                description: description
            }
            dataEntity = _.pickBy(dataEntity, v => !_.isNull(v) && !_.isUndefined(v));
            const response = await putEntity(dataEntity);
            if (response) {
                fetchDataEntity();
            }
        }
        setShow(false);
    }
    const checkValidity = () => {
        let isValidated = true;
        if (_.isEmpty(email) || _.isUndefined(email) || _.isNull(email)) {
            isValidated = false;
        }
        if (_.isEmpty(name) || _.isUndefined(name) || _.isNull(name)) {
            isValidated = false;
        }
        return isValidated;
    }
    return (
        <>
            <span className='text-primary me-2 cl-pointer' onClick={handleShow}><IconEditCircle /></span>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Update Entity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className='mb-3' controlId='email'>
                            <Form.Label>Email address<span className='required ms-1'>(*)</span></Form.Label>
                            <Form.Control
                                type='email'
                                placeholder='name@example.com'
                                autoFocus
                                value={email}
                                name='email'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label>Company<span className='required ms-1'>(*)</span></Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Neotiq JSC'
                                autoFocus
                                value={name}
                                name='name'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='website'>
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='neotiq.com'
                                autoFocus
                                value={website}
                                name='website'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='address'>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                value={address}
                                name='address'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='numberPhone'>
                            <Form.Label>Phone number</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                value={numberPhone}
                                name='numberPhone'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='zipcode'>
                            <Form.Label>ZIP Code</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                value={zipcode}
                                name='zipcode'
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Switch
                            className='mb-3'
                            id='status'
                            type='switch'
                            autoFocus
                            checked={status}
                            name='status'
                            onChange={handleChange}
                            label='Status'
                        />
                        <Form.Group className='mb-3' controlId='description'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as='textarea'
                                rows={3}
                                value={description}
                                name='description'
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='default' type='button' onClick={handleClose}><IconX />Cancel</Button>
                    <Button variant='primary ms-auto' type='submit' onClick={handleSubmit}><IconDownload />Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UpdateEntity;