import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import OutPageLayout from '../Layout/OutPageLayout';
import Spinner from '../../components/Spinner';

import LazyLogin from '../Login';
/**
 * Configuration router Un Authorize
 * Match path and Page layout
 */
const UnAuthorizedRoute = () => (
    <Switch>
        <OutPageLayout path="/auth/login" render={matchprops => (
            <Suspense fallback={<Spinner />}>
                <LazyLogin {...matchprops} />
            </Suspense>)} />
    </Switch>
)
export default React.memo(UnAuthorizedRoute);