import React from 'react';
import logo from '../../assets/img/logoPoolp.png';

const style = {
    width: "110px"
}

const LogoPoolp = () => (
    <img className="navbar-brand-image" src={logo} style={style} alt="Poolp"/>
)
export default React.memo(LogoPoolp);
