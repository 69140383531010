/* eslint-disable react/prop-types */
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import _ from 'lodash';
import * as ActionTypes from './actions';
import { getProfile } from './services';
import NotiError from './Errors';

const ProfileUpdate = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [numberPhone, setNumberPhone] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [gender, setGender] = useState('');
    const [errors, setErrors] = useState([])

    const userInfo = JSON.parse(localStorage.getItem(Storage.USER_INFO))
    const handleChange = (e) => {
        const name = e.target.name
        switch (name) {
            case 'firstName':
                setFirstName(e.target.value)
                break;
            case 'lastName':
                setLastName(e.target.value)
                break;
            case 'userName':
                setUserName(e.target.value)
                break;
            case 'email':
                setEmail(e.target.value)
                break;
            case 'timeZone':
                setTimeZone(e.target.value)
                break;
            case 'numerPhone':
                setNumberPhone(e.target.value)
                break;
            case 'address':
                setAddress(e.target.value)
                break;
            case 'city':
                setCity(e.target.value)
                break;
            case 'zipCode':
                setZipCode(e.target.value)
                break;
            case 'countryCode':
                setCountryCode(e.target.value)
                break;
            case 'gender':
                setGender(e.target.value)
                break;
            default:
                break;
        }
    }

    // Get all avaiable floor unit and building all
    useEffect(() => {
        console.log('props.resultProfile: ', props.resultProfile);
        const resultProfile = props.resultProfile;
        if (_.get(resultProfile, 'result.data', null) !== null) {
            props.dispatch({ type: ActionTypes.PROFILE_DETAIL.RESET });

        }
    }, []);


    // DidUpdate reset action
    useLayoutEffect(() => {
        // Action reset
        props.dispatch({ type: ActionTypes.PROFILE_UPDATE.RESET })
    });

    const handleSubmit = (e) => {
        e.preventDefault()
        const params = {
            firstName, lastName, userName, email, timeZone, numberPhone, address, city,
            zipCode, countryCode, gender
        };
        updateProfile(params);
    };

    // Dispatch api create Question pool
    const updateProfile = (params) => {
        props.dispatch({ type: ActionTypes.PROFILE_UPDATE.REQUESTED, detail: params });
    };

    const profileInfo = _.get(props, 'resultProfile.Profile')

    // Notification Errors
    if (_.get(profileInfo, 'done') && _.get(profileInfo, 'error')) {
        const errorMessage = _.get(profileInfo, 'message', [])
        if (_.size(errorMessage) > 0 && errors !== errorMessage) {
            setErrors(errorMessage);
        }
    }

    const callbackClearErrors = () => {
        setErrors([]);
    };

    return (
    <div>


    </div>);

};

const mapStateToProps = (state) => ({
    resultProfile: state.profile
});

export default connect(mapStateToProps)(ProfileUpdate);