/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'

import "react-chat-elements/dist/main.css"
import { MessageList } from "react-chat-elements";
import { Input } from 'react-chat-elements'
import { Button } from "react-chat-elements";
import { IconSend } from '@tabler/icons';
import _ from 'lodash';
import Form from 'react-bootstrap/Form';
import { Avatar } from 'react-chat-elements';

import avatar from '../../assets/img/letterP.svg';
import smile from '../../assets/img/smile.svg'
import chatTyping from '../../assets/img/chat-typing.svg';

import Aside from '../../components/Aside'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import * as Storage from '../../storage'

import { postPrompt } from './services'

import { ObjectToQueryString } from "../../utils"
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;
var eventSource = null
/**
 * Prompt page
 * constructor: data userinfo
 */
class Prompt extends React.Component {
    constructor(props) {
        super(props)
        console.log()
        const userInfo = JSON.parse(localStorage.getItem(Storage.USER_INFO))
        this.state = {
            userInfo: userInfo,
            error: false,
            errorMessage: null,
            isLoading: false,
            prompt: '',
            responsePrompt: '',
            dataSource: [],
            buttonDisabled: ''
        }
    }

    /**
     * Receive props data
     * @param {*} props
     * @param {*} state 
     */
    static getDerivedStateFromProps(props, state) {
        
        return state
    }

    handleSubmitPrompt = async (event) => {
        event.preventDefault();
        const { dataSource, prompt, responsePrompt } = this.state;
        this.setState({
            prompt: '',
            buttonDisabled: 'disabled'
        });
        var [ ...dataFlex] = dataSource
        const userPrompt = {
            position: 'right',
            type: 'text',
            title: this.state.userInfo.first_name + ' ' + this.state.userInfo.last_name,
            avatar: smile,
            avatarFlexible: true,
            date: new Date(),
            text: prompt
        }
        const poolpaiPrompt = {
            position: 'left',
            type: 'text',
            title: 'Poolpai',
            avatar: avatar,
            avatarFlexible: true,
            date: new Date(),
            text: responsePrompt
        }
        const dataInit = [userPrompt, poolpaiPrompt]
        if (_.isEmpty(dataSource)) {
            dataFlex = dataInit;
        } else {
            dataFlex.push(userPrompt, poolpaiPrompt);
        }
        this.eventConferences(dataFlex)
        await postPrompt({ prompt: prompt });
    }

    handleChangePrompt = (event) => {
        this.setState({
            prompt: event.target.value
        });
    }

    eventConferences = (dataFlex) => {
        let jwtToken = JSON.parse(localStorage.getItem(Storage.JWT_TOKEN))
        const params = { token: jwtToken }
        if (eventSource) eventSource.close()
        this.setState({ dataSource: dataFlex })
        eventSource = new EventSource(`${BASE_URL}/ai-prompt?${ObjectToQueryString(params)}`)
        eventSource.addEventListener('message', (event) => {
            const eventData = JSON.parse(event.data)
            console.log('eventData: ', eventData);
            const indexPoolp = _.findLastIndex(dataFlex, { title: 'Poolpai' });
            if (indexPoolp !== -1) {
                dataFlex[indexPoolp].text = dataFlex[indexPoolp].text + eventData.response;
                this.setState({ dataSource: dataFlex })
            }
            if (eventData.done) {
                this.setState({ buttonDisabled: '' });
            }
        })
    }

    /* --------------------------------------------
            RENDERING
    -------------------------------------------- */
    render() {
        const { match } = this.props
        const { userInfo } = this.state
        return (
            <React.Fragment>
                <Aside match={match} />
                <Header userInfo={userInfo} />
                <div className="page-wrapper mt-55">
                    <div className="page-body">
                        <div className="container-xl">
                            <div className="row row-deck row-cards prompt-content">
                                <MessageList
                                    className='message-list'
                                    lockable={true}
                                    toBottomHeight={'100%'}
                                    dataSource={this.state.dataSource}
                                />
                            </div>
                        </div>
                        <div className="container-xl">
                            <div className="row">
                                <div className="row-cards input-ms mt-5">
                                    <Form onSubmit={this.handleSubmitPrompt} className='form-request' >
                                        <Input
                                            placeholder="Type here..."
                                            multiline={false}
                                            value={this.state.prompt}
                                            onChange={this.handleChangePrompt}
                                            rightButtons={<Button
                                                text={''}
                                                onClick={() => this.handleSubmitPrompt}
                                                title='Send'
                                                type='Submit'
                                                icon={{
                                                    float: 'left',
                                                    size: 15,
                                                    component: <IconSend />
                                                }}
                                                disabled={this.state.buttonDisabled}
                                            />}
                                        />
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    resultMe: state.me,
    resultAuth: state.auth
})

export default connect(mapStateToProps)(Prompt)