import React from 'react';
import logo from '../../assets/img/logoPoolp.png';

const style = {
    width: "160px"
}

const LogoPoolp = () => (
    <img src={logo} style={style} alt="Generator Content"/>
)
export default React.memo(LogoPoolp);
