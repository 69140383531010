/* eslint-disable react/prop-types */
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { IconArrowBackUp } from '@tabler/icons';
import { Form, Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';
import * as ActionTypes from './actions';
import { getProfile } from './services';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ModalImage from './ModalImage';
import * as Storage from '../../storage'
import ModalPassword from './ModalPassword';

const ProfilePage = (props) => {
    const userStorage = JSON.parse(localStorage.getItem(Storage.USER_INFO));
    const [userInfo, setUserInfo] = useState(userStorage);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [numberPhone, setNumberPhone] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [gender, setGender] = useState('');
    const [errors, setErrors] = useState([])

    const handleChange = (e) => {
        const name = e.target.name
        switch (name) {
            case 'userName':
                setUserName(e.target.value)
                break;
            case 'firstName':
                setFirstName(e.target.value)
                break;
            case 'lastName':
                setLastName(e.target.value)
                break;
            case 'email':
                setEmail(e.target.value)
                break;
            case 'timeZone':
                setTimeZone(e.target.value)
                break;
            case 'numberPhone':
                setNumberPhone(e.target.value)
                break;
            case 'address':
                setAddress(e.target.value)
                break;
            case 'city':
                setCity(e.target.value)
                break;
            case 'zipcode':
                setZipcode(e.target.value)
                break;
            case 'countryCode':
                setCountryCode(e.target.value)
                break;
            case 'gender':
                setGender(e.target.value)
                break;
            default:
                break;
        }
    }

    // Get load data init profile
    useEffect(() => {
        async function fetchDataProfile () {
            const response = await getProfile();
            localStorage.setItem(Storage.USER_INFO, JSON.stringify(response.userInfo));
            setUserInfo(_.get(response, 'userInfo', {}));
            setFirstName(_.get(response, 'userInfo.first_name', ''));
            setLastName(_.get(response, 'userInfo.last_name', ''));
            setUserName(_.get(response, 'userInfo.user_name', ''));
            setRole(_.get(response, 'userInfo.role', 0));
            setEmail(_.get(response, 'userInfo.email', ''));
            setNumberPhone(_.get(response, 'userInfo.number_phone', ''));
            setAddress(_.get(response, 'userInfo.address', ''));
            setGender(_.get(response, 'userInfo.gender', 0));
        }
        fetchDataProfile();
    }, []);


    // DidUpdate reset action
    useLayoutEffect(() => {
        // Action reset
        // props.dispatch({ type: ActionTypes.PROFILE_DETAIL.RESET })
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        let params = {
            firstName, 
            lastName, 
            userName, 
            email, 
            timeZone, 
            numberPhone, 
            address, 
            city, 
            zipcode, 
            countryCode, 
            gender
        };
        const result = _.mapKeys(params, function (value, key) {
            return _.snakeCase(key)
        });
        updateProfile(result);
    };

    // Dispatch api create Question pool
    const updateProfile = (params) => {
        props.dispatch({ type: ActionTypes.PROFILE_UPDATE.REQUESTED, detail: params });
    };

    const profileInfo = _.get(props, 'resultProfile.Profile')

    // Notification Errors
    if (_.get(profileInfo, 'done') && _.get(profileInfo, 'error')) {
        const errorMessage = _.get(profileInfo, 'message', [])
        if (_.size(errorMessage) > 0 && errors !== errorMessage) {
            setErrors(errorMessage);
        }
    }
    return (<>
        <Header userInfo={userInfo} />
        <div className='page-wrapper mt-55'>
            <div className='page-body'>
                <div className='container-xl'>
                    <Link className='nav-link d-inline' to='/'>
                        <IconArrowBackUp />
                    </Link>
                    <h3 className='card-title col'>Profile</h3>
                    <Row>
                        <Col xs={12} md={3}>
                            <ModalImage />
                            <div className='m-2'>
                                <ModalPassword />
                            </div>
                        </Col>
                        <Col>
                            <Form onSubmit={(e) => handleSubmit(e)} className='row'>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='firstName'>First name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        id='firstName'
                                        name='firstName'
                                        aria-describedby='firstName'
                                        value={firstName} 
                                        onChange={(e) => handleChange(e)}
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='lastName'>Last name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        id='lastName'
                                        name='lastName'
                                        aria-describedby='lastName'
                                        value={lastName}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='userName'>User name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        id='userName'
                                        name='userName'
                                        aria-describedby='userName'
                                        value={userName}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='email'>Email</Form.Label>
                                    <Form.Control
                                        type='text'
                                        id='email'
                                        name='email'
                                        aria-describedby='email'
                                        value={email}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='numberPhone'>Number phone</Form.Label>
                                    <Form.Control
                                        type='text'
                                        id='numberPhone'
                                        name='numberPhone'
                                        aria-describedby='number-phone'
                                        datamask='(00) 0000-0000' 
                                        datamaskvisible='true' 
                                        placeholder='(00) 0000-0000' 
                                        value={numberPhone}
                                        minLength={8}
                                        maxLength={16}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='role'>Role</Form.Label>
                                    <Form.Select id='role'
                                        name='role'
                                        aria-label='role'
                                        disabled
                                        value={role}>
                                        {role === 1 && <option value={1}>User</option>}
                                        {role === 2 && <option value={2}>Assistant</option>}
                                        {role === 3 && <option value={3}>Admin</option>}
                                        {role === 4 && <option value={4}>Super Admin</option>}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='address'>Address</Form.Label>
                                    <Form.Control
                                        type='text'
                                        id='address'
                                        name='address'
                                        aria-describedby='address'
                                        value={address}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='gender'>Gender</Form.Label>
                                    <Form.Select id='gender' 
                                        name='gender'
                                        aria-label='Gender' 
                                        value={gender}
                                        onChange={(e) => handleChange(e)}>
                                        <option value={0}>OTHER</option>
                                        <option value={1}>MALE</option>
                                        <option value={2}>FEMALE</option>
                                    </Form.Select>
                                </Form.Group>
                                <div>
                                    <Button 
                                        variant='primary' 
                                        className='btn btn-primary'
                                        type='submit'>
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </div>
                
            </div>
            <Footer />
        </div>

        </>);
        
};

const mapStateToProps = (state) => ({
        resultProfile: state.profile
});

export default connect(mapStateToProps)(ProfilePage);