import fetch from 'isomorphic-fetch';
import _ from 'lodash';
import * as Utils from '../utils';
import { API_ROOT } from '../config';

const BASE_URL = API_ROOT;

/**
 * get me data, its the same auth login response
 */
export const getMe = () => {
    return fetch(`${BASE_URL}/auth/me`, {
        method: 'GET',
        headers: Utils.commonHeaders()
    }).then(Utils.handleNon200s).catch(error => {
        if (!_.isNull(error)) {
            // console.error(error);
            alert("net::ERR_CONNECTION_REFUSED");
        }
    });
}