import React, { Component } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { IconEditCircle, IconDownload, IconCircleX } from '@tabler/icons'

import * as Types from './actions'
import _ from "lodash";

class ModalEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
            label: this.props.attribute.label,
            value: this.props.attribute.value
        })
    }

    handleChange = (event) => {
        this.setState({
            label: event.target.value,
            value: _.snakeCase(event.target.value)
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { label, value } = this.state
        const attribute = {
            label, value, id: this.props.attribute.id
        };
        this.props.dispatch({ type: Types.ATTRIBUTE_UPDATE.REQUESTED, detail: attribute })
        this.toggle();
    }

    render() {
        const { label, value } = this.state
        return (
            <React.Fragment>
                <span onClick={this.toggle} className="text-primary me-2 cl-pointer"><IconEditCircle /></span>
                <Modal show={this.state.isOpen} onHide={this.toggle} className="modal-blur" centered size="lg" >
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update attribute</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-3 row">
                                <div className="col-lg-6">
                                    <label htmlFor="label" className="form-label">Label</label>
                                    <input id="label" type="text" className="form-control" name="label" placeholder="Label attribute" value={label} onChange={this.handleChange} />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="value" className="form-label">Value</label>
                                    <input id="value" type="text" className="form-control" name="value" placeholder="Key attribute" value={value} readOnly />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link-secondary" onClick={this.toggle}><IconCircleX/>Cancel</Button>
                            <Button variant="primary ms-auto" type="submit"><IconDownload />Save</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalEdit;

