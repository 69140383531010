let backendHost; // endpoint backend
let imageHost;  // endpoint storage image
/*************** window && window.location && window.location.host *******************************/
const hostname = window && window.location && window.location.hostname; // detect hostname frontend

/**
 * Configuration environment depend on hostname
 */
if (hostname === 'poolpai-front.neotiq.net') {
    backendHost = 'https://poolpai-api.neotiq.net';
    imageHost = 'https://poolpai-api.neotiq.net';

} else if (hostname === 'localhost:3000') {
    backendHost = 'http://localhost:1337';
    imageHost = 'http://localhost:1337';
    
} else {
    backendHost = process.env.REACT_APP_BACKEND_HOST || 'https://poolpai-api.neotiq.net';
    imageHost = process.env.REACT_APP_IMAGE_HOST || 'https://poolpai-api.neotiq.net';
}

export const API_ROOT = `${backendHost}/api/v1`;
export const PATH_ROOT = `${imageHost}`;
export const DEFAULT_LOCALE = 'en';
