import fetch from 'isomorphic-fetch';
import * as Utils from '../../utils';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;

export const getListChat = async () => {
    const response = await fetch(`${BASE_URL}/list-chat`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}
export const getChat = async (params) => {
    const response = await fetch(`${BASE_URL}/ai-chat/${params.chatId}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}
export const postChat = async (data) => {
    const response = await fetch(`${BASE_URL}/ai-chat`, {
        method: 'POST',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    });
    return response;
}