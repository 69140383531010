export const CHAT_LIST = {
    REQUESTED: "CHAT_LIST_REQUESTED",
    SUCCEEDED: "CHAT_LIST_SUCCEEDED",
    FAILED: "CHAT_LIST_FAILED",
    RESET: "CHAT_LIST_RESET",
};
export const CHAT_DETAIL = {
    REQUESTED: "CHAT_DETAIL_REQUESTED",
    SUCCEEDED: "CHAT_DETAIL_SUCCEEDED",
    FAILED: "CHAT_DETAIL_FAILED",
    RESET: "CHAT_DETAIL_RESET",
};