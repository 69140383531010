/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Col } from 'react-bootstrap';
import _ from 'lodash';
import { IconRotate2 } from '@tabler/icons';
import { postProduct } from './services';
import { ButtonClipLoader } from '../../components/Loading';
import Header from '../../components/Header';
import Aside from '../../components/Aside';
import Footer from '../../components/Footer';
import * as Storage from '../../storage'
import AddProduct from './AddProduct';
const NewProductPage = (props) => {
    let history = useHistory();
    const userInfo = JSON.parse(localStorage.getItem(Storage.USER_INFO));
    const [products, setProducts] = useState([{
        sku: '',
        attributes: [{
            label: '',
            value: '',
            err_label: false,
            err_value: false
        }],
        err_sku: false
    }]);
    const [description, setDescription] = useState(true);
    const [metaDescription, setMetaDescription] = useState(true);
    const [metaTitle, setMetaTitle] = useState(true);
    const [metaKeyword, setMetaKeyword] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    const handleChange = (e) => {
        const name = e.target.name
        switch (name) {
            case 'description':
                setDescription(e.target.checked)
                break;
            case 'metaDescription':
                setMetaDescription(e.target.checked)
                break;
            case 'metaTitle':
                setMetaTitle(e.target.checked)
                break;
            case 'metaKeyword':
                setMetaKeyword(e.target.checked)
                break;
            default:
                break;
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoaded(true);
        const isValidate = checkValidity();
        if (isValidate) {
            const detail = [...products];
            if (Array.isArray(detail) && !_.isEmpty(detail)) {
                detail.forEach((element) => { delete element.err_sku });
                const params = {
                    textgen: {
                        description,
                        meta_description: metaDescription,
                        meta_title: metaTitle,
                        meta_keyword: metaKeyword
                    },
                    products: detail
                };
                const result = await postProduct(params);
                if (result) {
                    setIsLoaded(false);
                    history.push('/products');
                }
            }
        } else {
            setIsLoaded(false);
        }
    };

    /**
     * Verify before submit action
     */
    const checkValidity =() => {
        let isValidated = true;
        const dataValidate = [...products];
        if (Array.isArray(dataValidate) && !_.isEmpty(dataValidate)) {
            dataValidate.forEach((element, key) => {
                if (element.sku.trim() === '') {
                    dataValidate[key]['err_sku'] = true;
                    isValidated = false;
                }
                // const attributes = detail[key].attributes;
                if (Array.isArray(dataValidate[key].attributes) && !_.isEmpty(dataValidate[key].attributes)) {
                    dataValidate[key].attributes.forEach((element, tKey) => {
                        if (element.label.trim() === '') {
                            dataValidate[key].attributes[tKey]['err_label'] = true;
                            isValidated = false;
                        }
                        if (element.value.trim() === '') {
                            dataValidate[key].attributes[tKey]['err_value'] = true;
                            isValidated = false;
                        }
                    });
                } else {
                    isValidated = false;
                }
                setProducts(dataValidate);
            });
        }
        return isValidated;
    }

    const handleProduct = (products) => {
        setProducts(products);
    }
    return (<>
        <Aside match={props.match} />
        <Header userInfo={userInfo} />
        <div className='page-wrapper mt-55'>
            <div className='page-body'>
                <div className='row row-deck row-cards'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title col'>Add product</h3>
                            </div>
                            <div className='card-body border-bottom py-3'>
                                <Form className='col-12' onSubmit={(e) => handleSubmit(e)} >
                                    <div className='d-table'>
                                        <label className='form-check'>
                                        <input className='form-check-input' type='checkbox' name='description' defaultChecked={description} onChange={handleChange} />
                                            <span className='form-check-label'>Description</span>
                                        </label>
                                        <label className='form-check'>
                                        <input className='form-check-input' type='checkbox' name='metaDescription' defaultChecked={metaDescription} onChange={handleChange} />
                                            <span className='form-check-label'>Meta Description</span>
                                        </label>
                                        <label className='form-check'>
                                        <input className='form-check-input' type='checkbox' name='metaTitle' defaultChecked={metaTitle} onChange={handleChange} />
                                            <span className='form-check-label'>Meta Title</span>
                                        </label>
                                        <label className='form-check'>
                                        <input className='form-check-input' type='checkbox' name='metaKeyword' defaultChecked={metaKeyword} onChange={handleChange} />
                                            <span className='form-check-label'>Meta Keyword</span>
                                        </label>
                                    </div>
                                    <hr/>
                                    <AddProduct handleProduct={handleProduct} products={products} />
                                    <div className='card-footer d-flex align-items-center'>
                                        <Col className='text-center'>
                                            <ButtonClipLoader 
                                                disabled={false} 
                                                variant='primary'
                                                icon={<IconRotate2 />} 
                                                isLoading={isLoaded} 
                                                type='submit'
                                                label='Submit'
                                            />
                                        </Col>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </>);
};

export default NewProductPage;